import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { spaceToNBSP } from '../../utils/spaceToNBSP';

const reiseConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Reise pluss',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Reise',
            description: 'En god reiseforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: 'Ulykke',
            help: `Kan dekke behandlingsutgifter etter ulykke og gi erstatning ved varig invaliditet eller død.`,
            checkedRows: 2,
        },
        {
            title: 'Enkeltgjenstander',
            help: `Dekker enkeltgjenstander du har med deg på reise med inntil ${spaceToNBSP(
                '15 000',
            )} kroner hvis de blir stjålet, ødelagt av andre eller blir skadd eller ødelagt i trafikkuhell, brann, på grunn av vannskade eller naturskade.`,
            checkedRows: 2,
        },
        {
            title: 'Sykkel',
            help: `Hvis sykkelen din blir skadd eller går tapt dekker vi inntil ${spaceToNBSP('10 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Hjemtransport og behandling',
            help: 'Hvis du blir syk på reise dekker vi hjemreisen og behandlingen din.',
            checkedRows: 2,
        },

        {
            title: 'Bagasje',
            help: `Hvis du har innsjekket bagasjen din og den går tapt eller blir forsinket dekker vi inntil ${spaceToNBSP(
                '6 000',
            )} kroner til kjøp av nødvendige erstatninger og inntil ${spaceToNBSP(
                '15 000',
            )} kroner i erstatning hvis den går tapt.`,
            checkedRows: 2,
        },
        {
            title: 'Avbestilling',
            help: `Hvis du eller nær familie blir syk eller reisen må avbrytes på grunn av for eksempel naturhendelser erstatter vi reiseutgiftene dine som du ikke får refundert med inntil ${spaceToNBSP(
                '50 000',
            )} kroner per tilfelle.`,
            checkedRows: 2,
        },
        {
            title: 'Forsinkelse',
            help: 'Hvis noe uforutsett skjer og du blir forsinket på reisen dekker vi utgifter for overnatting og å ta igjen reiseplanen din.',
            checkedRows: 2,
        },
        {
            title: 'Enkeltgjenstander',
            help: `Utvidet dekning for enkeltgjenstander. Hvis enkeltgjenstander du har med deg på reise blir stjålet, ødelagt av andre eller blir skadd eller ødelagt i trafikkuhell, brann, etter vannskade eller naturskade erstatter vi med inntil ${spaceToNBSP(
                '40 000',
            )} kroner.`,
            checkedRows: 1,
        },
        {
            title: 'Sykkel',
            help: `Utvidet sum for skade eller tap av sykkel. Hvis sykkelen din blir skadd eller går tapt dekker vi inntil ${spaceToNBSP(
                '40 000',
            )} kroner.`,
            checkedRows: 1,
        },
        {
            title: 'Ingen egenandel ved skade',
            help: 'Du betaler ingen egenandel hvis det skjer en skade.',
            checkedRows: 1,
        },
        {
            title: 'Bagasje - ubegrenset sum',
            help: `Hvis du har innsjekket bagasjen din og den går tapt eller blir forsinket dekker vi inntil ${spaceToNBSP(
                '6 000',
            )} kroner til kjøp av nødvendige erstatninger og hele verdien i erstatning hvis den går tapt.`,
            checkedRows: 1,
        },
        {
            title: 'Avbestilling - ubegrenset sum',
            help: 'Hvis du eller nær familie blir syk eller reisen må avbrytes på grunn av for eksempel naturhendelser erstatter vi hele summen av reiseutgiftene dine som du ikke får refundert.',
            checkedRows: 1,
        },
        {
            title: 'Actionfylte aktiviteter',
            help: 'Ulykker som skjer mens du driver med fjellklatring, skisport, kiting og downhillkjøring på sykkel blir dekket.',
            checkedRows: 1,
        },
    ],
};

export default reiseConfig;
