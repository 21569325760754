import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const veteranbilConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Kasko',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Brann/tyveri',
            description: 'Dekkjer enkelte skader',
            color: 'medium',
        },
        {
            title: 'Ansvar',
            description: 'Dekkjer kun det lovpålagte',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: 'Dekkjer uavgrensa beløp for skader på personar, og inntil 100 millionar kroner for skader på andres køyretøy, eigedom eller eigedelar. Skader på eigen veteranbil er ikkje dekka av ansvarsforsikringa. For å få dette dekka må du enten ha brann/ tjuveri, som dekkjer nokre skadetilfelle, eller kaskoforsikring.',
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekkjer utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan for eksempel vere tvisteløysing ved kjøp og sal av veteranbil.`,
            checkedRows: 3,
        },
        {
            title: `Førar- og ${hyphenAsSoftHyphen('passasjer-ulukke')}`,
            help: 'Vi gir erstatning ved personskade på førar og/eller passasjer.',
            checkedRows: 3,
        },
        {
            title: 'Brann',
            help: 'Vi dekkjer skader ved brann, kortslutning i leidningsnett, lynnedslag og eksplosjon.',
            checkedRows: 2,
        },
        {
            title: 'Tjuveri',
            help: 'Vi dekkjer skader ved tjuveri og forsøk på tjuveri av eller frå køyretøy, og skadeverk som følgje av forsøk på tjuveri.',
            checkedRows: 2,
        },
        {
            title: 'Bagasje',
            help: `Dekkjer bagasje med inntil ${spaceToNBSP(
                '20 000',
            )} kroner. Gjeld ved skade på bagasje eller tjuveri.`,
            checkedRows: 2,
        },

        {
            title: `${hyphenAsSoftHyphen('Veg-hjelp')}`,
            help: 'Vi hjelper deg ved motorstopp og annan køyreteknisk driftsstopp som hindrar deg frå å køyre vidare. Dette inkluderer reparasjon på staden eller tauing av bil til næraste verkstad. Du får òg veghjelp viss du går tom for drivstoff.',
            checkedRows: 1,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('veteran-køyretøy')}`,
            help: 'Dekkjer skader ved samanstøyt, utforkøyring, velting, hærverk eller annan uføresett hending eller eit uhell som skjer utan varsel på eigen veteranbil.',
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Sikkerheits-utstyr')}`,
            help: 'Dekkjer sikkerheitsutstyr som tilhøyrer veterankøyretøyet.',
            checkedRows: 1,
        },
        {
            title: `Fastmontert ${hyphenAsSoftHyphen('tilleggs-utstyr')}`,
            help: `Dekkjer fastmontert tilleggsutstyr med inntil ${spaceToNBSP('20 000')} kroner.`,
            checkedRows: 1,
        },
    ],
};

export default veteranbilConfig;
