import React from 'react';

import { useConfig } from '../default-config';
import { useTranslation } from '../translations';
import { BuyForsikringButton } from './buy-forsikring-button';
import { VilkarAndProduktinformasjonLinks } from './vilkar-and-produktinformasjon-links';

import './comparison-table-button-and-links.scss';

export const ComparisonTableButtonAndLinks = () => {
    const { comparisonTableButtonTexts } = useTranslation();
    const { kjopbuttonlink } = useConfig();

    const buttonText =
        (kjopbuttonlink?.url && comparisonTableButtonTexts.digitaltProdukt) ||
        comparisonTableButtonTexts.ikkeDigitaltProdukt;

    return (
        <div className="comparison-table-button-and-links-container">
            <BuyForsikringButton buttonText={buttonText} />
            <VilkarAndProduktinformasjonLinks />
        </div>
    );
};
