import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const droneConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: `${hyphenAsSoftHyphen('Bakke-kasko')}`,
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: `${hyphenAsSoftHyphen('Drone-ansvar')}`,
            description: 'Dekker kun det lovpålagte',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: `Dekker rettslig erstatningsansvar hvis dronen din gjør skade på andre personer og andres ting i henhold til EC785/2004.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekker nødvendige kostnader til advokat og andre kostnader i forbindelse med rettshjelp knyttet til dronen din.`,
            checkedRows: 2,
        },
        {
            title: 'Brann',
            help: `Dekker hvis dronen blir skadd eller går tapt i brann.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Vann-skade')}`,
            help: 'Dekker hvis dronen blir skadd eller går tapt på grunn av vannskade.',
            checkedRows: 1,
        },
        {
            title: 'Tyveri',
            help: 'Dekker hvis dronen blir stjålet.',
            checkedRows: 1,
        },
        {
            title: 'Uhell',
            help: 'Dekker hvis dronen blir skadd eller går tapt i ulykke mens den ikke er i bruk.',
            checkedRows: 1,
        },
        {
            title: 'Tilbehør',
            help: `Dekker plutselig og uforutsett tap eller skade på tilbehør til dronen, slik som kamera. Dekker inntil ${spaceToNBSP(
                '10 000',
            )} kroner.`,
            checkedRows: 1,
        },
    ],
};

export default droneConfig;
