import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const husConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Hus Pluss',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Hus',
            description: 'Ei god husforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: `Brann-, vass- og ${hyphenAsSoftHyphen('tjuveri-skadar')}`,
            help: 'Vi dekkjer brannskade på bygning og hageanlegg. Vi dekkjer også vasskadar frå røyrleidning etter brot, lekkasje eller oversvømming. Det gjeld også vassinntrenging frå terreng, grunn eller avløpsrøyr. Skadar på bygning i samband med innbrot og tjuveri.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Natur-skade')}`,
            help: 'Naturskade som kjem av skred, storm, flaum, stormflo, flodbølge, meteorittnedslag, jordskjelv eller vulkanutbrot.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: 'Som huseigar kan du få dekka utgifter til rettshjelp, viss du blir part i ein rettsleg tvist eller juridisk konflikt.',
            checkedRows: 2,
        },
        {
            title: `Bygning inntil 10 kvm er ${hyphenAsSoftHyphen('med-forsikra')}`,
            help: 'Frittståande bygningar på inntil 10 kvadratmeter på forsikringsstaden er medforsikra når bygget er nemnt i forsikringsbeviset.',
            checkedRows: 2,
        },
        {
            title: `Tap av ${hyphenAsSoftHyphen('husleige-inntekter')}`,
            help: 'Vi dekkjer tapte husleigeinntekter som følgje av skade som blir dekka av forsikringa.',
            checkedRows: 2,
        },
        {
            title: `Utett ${hyphenAsSoftHyphen('våt-rom')}`,
            help: `Plutseleg og uforutsett skade på våtrom. Ved skade på golv eller vegg i våtrom der røyr eller vasstett sjikt/membran er eldre enn 20 år, blir eigenandelen auka med kr ${spaceToNBSP('10 000')}.`,
            checkedRows: 2,
        },
        {
            title: `Utett ${hyphenAsSoftHyphen('ytter-tak')}`,
            help: `Plutseleg og uforutsett følgeskade ved vassinntrenging gjennom utett yttertak som er 40 år eller yngre. Når bygningen si taktekking er eldre enn 30 år, eller taktekking er eldre enn 15 år på flatt tak, blir eigenandelen auka med kr ${spaceToNBSP('10 000')}.`,
            checkedRows: 1,
        },
        {
            title: 'Skade på våtrom',
            help: `Plutseleg og uforutsett skade på våtrom. Eigenandelen blir auka med ${spaceToNBSP('6 000')} kroner per skade ved skade på golv eller vegg i våtrom der røyr eller vasstett sjikt/membran er eldre enn 40 år.`,
            checkedRows: 1,
        },
        {
            title: `Følgeskade ved ${hyphenAsSoftHyphen('handverkar-feil')}`,
            help: 'Følgeskadar på bygning som følgje av materialfeil, konstruksjonsfeil, prosjekteringsfeil eller uriktig montering. Dette gjeld verksemd med eige organisasjonsnummer. Forsikringa gjeld i inntil 10 år etter at arbeidet er ferdigstilt.',
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Sol-celle')}`,
            help: `Erstatter tapt straumproduksjon til eige bruk med inntil 50 kroner dagen viss solcelleanlegget montert på bygningen blir skada. Dekkjer inntil 24 md. tapt produksjon og maks ${spaceToNBSP('30 000')} kroner per skade.`,
            checkedRows: 1,
        },
    ],
};

export default husConfig;
