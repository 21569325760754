import React, { FC } from 'react';

import { ComparisonTable } from '@eika/comparison-table';

import productConfig, { ForsikringProducts } from '../comparison-table-config/product-config';
import { ComparisonTableButtonAndLinks } from '../components/comparison-table-button-and-links';
import { useConfig } from '../default-config';
import { COMPACT_TABLE_PRODUCTS } from '../utils/compactTableProducts';

import '../components/comparison-table-button-and-links.scss';

const ComparisonTableApp: FC<React.PropsWithChildren<unknown>> = () => {
    const { product, siteLang } = useConfig();

    const tableConfig = product && productConfig[product][siteLang];
    const showCompactTable = COMPACT_TABLE_PRODUCTS.includes(product as ForsikringProducts);

    return tableConfig ? (
        <div>
            <ComparisonTable
                tableHeadColumns={tableConfig.tableHeadColumns}
                rows={tableConfig.rows}
                compact={showCompactTable}
            />
            <ComparisonTableButtonAndLinks />
        </div>
    ) : null;
};

export default ComparisonTableApp;
