import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const campingvognConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: `${hyphenAsSoftHyphen('Camping-vogn')} Pluss`,
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Kasko',
            description: 'Dekkjer dei fleste skadar',
            color: 'medium',
        },
        {
            title: 'Brann/tjuveri',
            description: 'Dekkjer enkelte skadar',
        },
    ],
    rows: [
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekkjer utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan for eksempel vere tvisteløysing ved kjøp og sal av køyretøy.`,
            checkedRows: 3,
        },
        {
            title: 'Brann og tjuveri',
            help: 'Vi dekkjer skader ved brann, kortslutning i ledningsnett, lynnedslag og eksplosjon. Vi dekkjer også tyveri og forsøk på tyveri av eller fra campingvogn, og skadeverk som følge av forsøk på tyveri.',
            checkedRows: 3,
        },
        {
            title: 'Glas',
            help: `Dekkjer brotskadar på alle vindauge i vogna. Kan ruten reparerast, betaler du ingen eigenandel inntil 750 kroner. Må ruten byttast, er eigenandelen ${spaceToNBSP('3 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Veg-hjelp')}`,
            help: 'Vi hjelper deg ved motorstopp og anna kjøreteknisk driftsstopp som hindrar deg frå å køyre vidare. Dette inkluderer reparasjon på staden eller tauing til næraste verkstad. Du får også veghjelp om du går tom for drivstoff eller straum.',
            checkedRows: 2,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('camping-vogn')}`,
            help: 'Dekkjer skadar ved samanstøyt, utforkøyring, velting, hærverk eller anna uforutsett hending eller eit uhell som skjer utan varsel på eigen campingvogn.',
            checkedRows: 2,
        },
        {
            title: 'Fastmontert tilbehør',
            help: `Når fortelt/spikertelt er medforsikra, dekkjes også platting med fastmontert tilbehøyr med inntil ${spaceToNBSP(
                '50 000',
            )} kroner på Campingvogn Pluss. På Kasko dekkjes inntil ${spaceToNBSP('30 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Fastmontert tilleggsutstyr',
            help: `Fastmontert tilleggsutstyr dekkjes med inntil ${spaceToNBSP(
                '50 000',
            )} kroner for Campingvogn Pluss. For Kasko dekkjes inntil ${spaceToNBSP('20 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Bagasje og eigendelar',
            help: `Dekkjer bagasje og personlege eigendelar med inntil ${spaceToNBSP('20 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Fuktskadar',
            help: 'Dekkjer fuktskade i vegger, tak og golv i campingvogna inntil den er 15 år gamal. Krever årleg fuktsjekk utført av forhandlar eller verkstad.',
            checkedRows: 1,
        },
        {
            title: 'Skadedyr',
            help: 'Dekkjer skadar påført av mus, insekt og andre skadedyr. Tilleggsutstyr og bagasje er også dekka dersom dette blir skada samtidig som campingvogna.',
            checkedRows: 1,
        },
        {
            title: 'Ferieavbrot',
            help: `Ved ferieavbrot på grunn av skade som dekkjes av forsikringa, refunderer vi overnattings- og leigebilutgifter med inntil ${spaceToNBSP(
                '1 500',
            )} kroner per dag i inntil 14 dagar.`,
            checkedRows: 1,
        },
    ],
};

export default campingvognConfig;
