import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const ungConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'UNG verdigjenstand',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'UNG',
            description: 'En god forsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: 'Reise Pluss',
            help: `Vår reise- og ${hyphenAsSoftHyphen(
                'avbestillings-forsikring',
            )} gjeld med ein gong du reiser heimefrå, og gjeld både på jobb og ferie i inntil 77 dagar.`,
            checkedRows: 2,
        },
        {
            title: 'Innbu Pluss',
            help: `Innbuforsikringa sikrar alle tinga dine. Det er like viktig anten du eig eller leiger.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Ulukkes-forsikring')}`,
            help: `Ulukkesforsikring kan dekkje behandlingsutgiftene dine viss du har vore i ei ulukke og gir erstatning ved varig skade eller død.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Verdi-gjenstand')}`,
            help: 'For nokre kroner ekstra i månaden kan du velje å forsikre dine verdifulle gjenstandar. Då blir prisen 200 kroner i månaden.',
            checkedRows: 1,
            subTexts: [{ text: `inntil ${spaceToNBSP('15 000')} kr` }],
        },
    ],
};

export default ungConfig;
