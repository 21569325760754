import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const innboConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Innbo Pluss',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Innbo Standard',
            description: 'En god innboforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: `Brann-, vann- og ${hyphenAsSoftHyphen('tyveri-skader')}`,
            help: 'Blir tingene dine ødelagt av vannskader, skadet eller nedsotet i brann, eller rammet av kortslutning/lynnedslag så erstatter vi gjenstandene dine. Blir du utsatt for tyveri i hjemmet ditt erstatter vi gjenstandene som blir ødelagt eller stjålet.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Tyveri fra ute-areal')}`,
            help: `Dekker tyveri fra utearealet på forsikringsstedet. Innbo Pluss dekker inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Innbo dekker tyveri inntil ${spaceToNBSP('30 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Tyveri av sykkel',
            help: `Dekker tyveri av sykkel utenfor forsikringsstedet. Er sykkelen registrert i et FG-godkjent sykkelregister, halveres egenandelen ved tyveri. Innbo Pluss dekker sykkel inntil ${spaceToNBSP(
                '40 000',
            )} kroner. Med Innbo dekkes sykkel inntil ${spaceToNBSP('10 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Om-bygging av bolig på grunn av ulykke eller handi-kap')}`,
            help: 'Hvis et medlem av husstanden blir rullestolbruker etter en ulykke – eller får et barn som er avhengig av rullestol på grunn av et medfødt handikap – dekker vi nødvendige merutgifter til ombygging av bolig.',
            checkedRows: 2,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('mat-varer og lukt-skade, på fryser og kjøle-skap')}`,
            help: 'Dekker skader på matvarer som følge av tilfeldig temperaturstigning i fryser, kjøleskap og kjølehjørne. Dette gjelder også luktskade som følge av skader på matvarer.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Identitets-tyveri')}`,
            help: 'Dekker identitetstyveri der noen uten ditt samtykke, bruker din identitet til å begå økonomisk svindel eller annen kriminell handling.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Skade-dyr')}`,
            help: `Om du får problemer med mus, rotter, skjeggkre, veggdyr eller andre skadeinsekter dekker vi bekjempelse med inntil ${spaceToNBSP('150 000')}.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Transport-skade ved flytting')}`,
            help: `Dekker skader på innbo i forbindelse med flytting til ny bolig, hvis du flytter selv. Gjelder for inntil ${spaceToNBSP(
                '100 000',
            )} kroner.`,
            checkedRows: 1,
        },
        {
            title: 'Uhell hjemme',
            help: `Vi gir inntil ${spaceToNBSP('100 000')} i erstatning om tingene dine blir skadd eller ødelagt i et uhell. For eksempel om TVen detter i gulvet. Egenandelen er 2000 uansett hvor høy egenandel du har valgt ellers.`,
            checkedRows: 1,
        },
        {
            title: 'Uhell i hele verden',
            help: `Mistet du mobilen i Trevi-fontenen eller kjørte bussen over hodetelefonene dine? Vi dekker plutselige og uforutsette skader på eiendelene dine med inntil ${spaceToNBSP(
                '30 000',
            )} kroner uansett hvor du er i verden. Enten det er på butikken for å handle, eller om du er på sydenferie. Egenandelen er 2000 uansett hvor høy egenandel du har valgt ellers.`,
            checkedRows: 1,
        },
    ],
};

export default innboConfig;
