import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

const kritisksykdomConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Kreft',
            checkedRows: 1,
            help: 'Ondartede svulster, Blodkreft, Lymfekreft, Benmargskreft.',
        },
        {
            title: 'Hjerte',
            checkedRows: 1,
            help: `Hjerteinfarkt, Hjerteoperasjon (bypassoperasjon, PCI), Hjerteklaffkirurgi, Utposning på hovedpulsåren (aortaaneurisme).`,
        },
        {
            title: 'Hjerne',
            checkedRows: 1,
            help: `Hjerneslag, aneurisme.`,
        },
        {
            title: 'Autoimmune og nevrologiske sykdommer',
            checkedRows: 1,
            help: `ALS og andre motornevronsykdommer, Multippel sklerose (MS), Parkinsons sykdom, Alzheimers sykdom.`,
        },
        {
            title: 'Annet',
            checkedRows: 1,
            help: `Nyresvikt; organtransplantasjon; blindhet; døvhet; tap av taleevne; alvorlig brannskade; godartede svulster i hjerne, hjernehinne eller ryggmarg; tverrsnittlammelser; tap av ben eller arm.`,
        },
    ],
};

export default kritisksykdomConfig;
