import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const batConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Båt Pluss',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: `${hyphenAsSoftHyphen('Maskin-skade')}`,
            description: 'En god båtforsikring',
            color: 'medium',
        },
        {
            title: 'Kasko',
            description: 'Dekker de fleste skader',
            color: 'light',
        },
        {
            title: 'Brann/tyveri',
            description: 'Dekker enkelte skader',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: `Dekker rettslig erstatningsansvar som du som båteier eller fører kan pådra deg for skader påført personer og andres eiendeler.`,
            checkedRows: 4,
        },
        {
            title: `Fører- og ${hyphenAsSoftHyphen('passasjer-ulykke')}`,
            help: 'Gir erstatning ved personskade på fører og/eller passasjer.',
            checkedRows: 4,
        },
        {
            title: 'Brann',
            help: 'Vi dekker skader ved brann, kortslutning i ledningsnett, lynnedslag og eksplosjon.',
            checkedRows: 4,
        },
        {
            title: 'Tyveri',
            help: 'Vi dekker skader ved tyveri og forsøk på tyveri av eller fra båt, og skadeverk som følge av forsøk på tyveri.',
            checkedRows: 4,
        },
        {
            title: `Bagasje og ${hyphenAsSoftHyphen('eien-deler')}`,
            help: 'Dekker bagasje og personlige eiendeler.',
            subTexts: [
                { text: `Inntil ${spaceToNBSP('30 000')} kroner` },
                { text: `Inntil ${spaceToNBSP('10 000')} kroner` },
                { text: `Inntil ${spaceToNBSP('10 000')} kroner` },
            ],
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Berge-lønn')}`,
            help: `Bergelønn dekkes når båten er utsatt for skader og ulykker som forsikringen dekker.`,
            checkedRows: 3,
        },
        {
            title: 'Redning',
            help: 'Dekker nødvendige utgifter til redning av båt.',
            checkedRows: 3,
        },
        {
            title: 'Skade på tilbehør',
            help: `Dekker skader på tilbehør som har naturlig sammenheng med båtens bruk.`,
            checkedRows: 3,
        },
        {
            title: 'Transport av skadet båt',
            help: `Ved skade dekkes rimelige omkostninger til transport eller midlertidig reparasjon av skadet båt eller tilbehør som er nødvendig for å flytte båten til nærmeste verksted som kan utføre reparasjonen.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Brudd-skader')} i maskineri`,
            help: `Dekker skader som gjelder brudd eller lekkasje som oppstår plutselig og uforutsett på båtens maskinelle utrustning. Det gjelder også for fastmontert tilbehør.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Ferie-avbrudd')}`,
            help: `Ved ferieavbrudd på grunn av skade som dekkes av forsikringen, dekker vi rimelige dokumenterte utgifter når båten repareres på stedet. Dette må avtales med oss og er begrenset til å gjelde i 7 dager.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Avbrudds-forsikring')}`,
            help: `Avbruddsforsikringen gjelder brann og tyveriskader i perioden 1. mai - 1. september. Fra og med 4. dag etter skade dekkes ${spaceToNBSP(
                '2 000',
            )} kroner per dag i inntil 14 dager.`,
            checkedRows: 1,
        },
        {
            title: `Skader på ${hyphenAsSoftHyphen('drivstoff-system')}`,
            help: `Dekker skade på drivstoffsystem som skyldes tilførsel av forurenset drivstoff, for eksempel dieseldyr eller vann. I tillegg erstattes feilfylling.`,
            checkedRows: 1,
        },
        {
            title: `Redning av båt med ${hyphenAsSoftHyphen('person-skade')}`,
            help: 'Dekker dokumenterte ekstrautgifter ved henting av båt etter personredning.',
            checkedRows: 1,
        },

        {
            title: `Joller med motor`,
            help: `Dekker jolle med motor med inntil ${spaceToNBSP('30 000')} kroner.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Fast-montert')} utstyr`,
            help: `Dekker fastmontert utstyr med inntil ${spaceToNBSP('10 000')} kroner.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Opplags-utstyr')}`,
            help: `Dekker opplagsutstyr som krybbe, stativ og presenning med inntil ${spaceToNBSP('15 000')} kroner.`,
            checkedRows: 1,
        },
    ],
};

export default batConfig;
