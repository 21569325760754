import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';

const kattConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Liv',
            checkedRows: 1,
            help: 'Gir erstatning hvis katten din dør eller blir borte. Og dekker kostnadene hvis katten din må avlives etter skade eller sykdom.',
        },

        {
            title: `${hyphenAsSoftHyphen('Bruks-verdi')}`,
            checkedRows: 1,
            help: 'Hvis katten din er trent til et bestemt formål eller er avlsdyr kan du få erstatning hvis katten mister bruksverdien sin.',
        },
        {
            title: `${hyphenAsSoftHyphen('Veterinær-utgifter')}`,
            checkedRows: 1,
            help: 'Dekker veterinærutgiftene til katten.',
        },
        {
            title: 'FirstVet',
            checkedRows: 1,
            help: 'Du får tilgang til videoveterinæren FirstVet. Ta veterinærtimen fra mobilen din der og når det passer.',
        },
    ],
};

export default kattConfig;
