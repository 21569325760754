import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const barnConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Medisinsk invaliditet som følgje av sjukdom eller ulykke',
            checkedRows: 1,
            help: 'Erstatning blir gitt viss barnet får nedsett funksjonsevne på grunn av sjukdom eller ulykke. Dette gir auka økonomisk spelerom for å møte behov og utgifter. Erstatninga baserast på invaliditetsgrad, med utbetaling opp til 25G ved medisinsk invaliditet og 15G ved ulykke.',
        },
        {
            title: `${hyphenAsSoftHyphen('Arbeids-uførleik')}: Månadleg utbetaling ved uførleik`,
            checkedRows: 1,
            help: `Dekninga gir månadleg utbetaling ved minst  ${spaceToNBSP(
                '50 %',
            )} arbeidsuførleik i 12 månader, unntatt ved psykiske lidingar. Barnet får utbetaling tidlegast ved 18 år, og forsikringa opphøyrer ved 26 år.`,
        },
        {
            title: `${hyphenAsSoftHyphen('Arbeids-uførleik')} – ${hyphenAsSoftHyphen('Uføre-kapital')}`,
            checkedRows: 1,
            help: 'Uførekapital gir engangsutbetaling ved varig uførleik, unntatt psykiske lidingar.',
        },
        {
            title: `Erstatning ved utvalde ${hyphenAsSoftHyphen('sjuk-domar')}/skader`,
            checkedRows: 1,
            help: 'Ved alvorleg sjukdom eller skade kan barnet få rask utbetaling av 3G etter stilt diagnose. Sjå den fulle lista under spørsmål og svar.',
        },
        {
            title: 'Økonomisk bistand til føresette: Ved utvalde sjukdomar og skader',
            checkedRows: 1,
            help: 'Engangsutbetaling på 2G viss barnet får alvorleg sjukdom eller skade, som kan gi økonomisk spelerom for føresette til å ta permisjon eller dekkje ekstra utgifter. Sjå den fulle lista under spørsmål og svar.',
        },
        {
            title: `Tilpassing av bustad og tilskot til tekniske ${hyphenAsSoftHyphen('hjelpe-middel')}`,
            checkedRows: 1,
            help: 'Behov for tekniske hjelpemiddel eller ombygging av bustad blir dekt med opptil 2G per skadetilfelle.',
        },
        {
            title: `${hyphenAsSoftHyphen('Sjukehus-opphald')}`,
            checkedRows: 1,
            help: 'Ved sjukehusopphald over 9 dagar utbetaler forsikringa 600 kr per døgn frå første dag, i opptil eitt år per skadetilfelle.',
        },
        {
            title: `Behov for ${hyphenAsSoftHyphen('lang-varig')} pleie/omsorg – ${hyphenAsSoftHyphen(
                'hjelpe-stønad',
            )}`,
            checkedRows: 1,
            help: `Supplement til hjelpestønad frå NAV blir gitt utan unntak for langvarig pleie og omsorg.`,
        },
        {
            title: `${hyphenAsSoftHyphen('Døds-fall')}`,
            checkedRows: 1,
            help: 'Viss barnet døyr, utbetalar vi ein engangserstatning på 1G som kan brukast til utgifter for gravferd og anna. Erstatninga utbetalast uansett årsak.',
        },
        {
            title: `${hyphenAsSoftHyphen('Behandlings-utgifter')} ved ulukke`,
            checkedRows: 1,
            help: 'Forsikringa dekkjer behandlingsutgifter ved ulykke med opptil 1G i Norden, inkludert tannlege, medisinsk behandling og reiseutgifter.',
        },
    ],
};

export default barnConfig;
