import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const batConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Båt Pluss',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: `${hyphenAsSoftHyphen('Maskin-skade')}`,
            description: 'Ei god båtforsikring',
            color: 'medium',
        },
        {
            title: 'Kasko',
            description: 'Dekkjer dei fleste skadar',
            color: 'light',
        },
        {
            title: 'Brann/tyveri',
            description: 'Dekkjer enkelte skader',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: 'Dekkjer rettsleg erstatningsansvar som du som båteigar eller førar kan pådra deg for skadar påført personar og andres eigendelar.',
            checkedRows: 4,
        },
        {
            title: `Førar- og ${hyphenAsSoftHyphen('passasjer-ulykke')}`,
            help: 'Gir erstatning ved personskade på førar og/eller passasjer.',
            checkedRows: 4,
        },
        {
            title: 'Brann',
            help: 'Vi dekkjer skadar ved brann, kortslutning i leidningsnett, lynnedslag og eksplosjon.',
            checkedRows: 4,
        },
        {
            title: 'Tjuveri',
            help: 'Vi dekkjer skadar ved tjuveri og forsøk på tjuveri av eller frå båt, og skadeverk som følgje av forsøk på tjuveri.',
            checkedRows: 4,
        },
        {
            title: `Bagasje og ${hyphenAsSoftHyphen('egien-delar')}`,
            help: 'Dekkjer bagasje og personlege eigendelar.',
            subTexts: [
                { text: `Inntil ${spaceToNBSP('30 000')} kroner` },
                { text: `Inntil ${spaceToNBSP('10 000')} kroner` },
                { text: `Inntil ${spaceToNBSP('10 000')} kroner` },
            ],
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Berge-lønn')}`,
            help: `Bergelønn dekkjes når båten er utsett for skadar og ulykker som forsikringa dekkjer.`,
            checkedRows: 3,
        },
        {
            title: 'Redning',
            help: 'Dekkjer nødvendige utgifter til redning av båt.',
            checkedRows: 3,
        },
        {
            title: 'Skade på tilbehør',
            help: 'Dekkjer skadar på tilbehør som har naturleg samanheng med båtens bruk.',
            checkedRows: 3,
        },
        {
            title: 'Transport av skada båt',
            help: 'Ved skade dekkjes rimelege omkostningar til transport eller mellombels reparasjon av skada båt eller tilbehør som er nødvendig for å flytte båten til nærmaste verkstad som kan utføre reparasjonen.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Brot-skadar')} i maskineri`,
            help: 'Dekkjer skadar som gjeld brot eller lekkasje som oppstår plutseleg og uforutsett på båtens maskinelle utstyr. Dette gjeld også for fastmontert tilbehør.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Ferie-avbrot')}`,
            help: 'Ved ferieavbrot på grunn av skade som dekkjes av forsikringa, dekkjer vi rimelege dokumenterte utgifter når båten blir reparert på staden. Dette må avtalast med oss og er avgrensa til å gjelde i 7 dagar.',
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Avbrots-forsikring')}`,
            help: `Avbrotforsikringa gjeld brann- og tyveriskadar i perioden 1. mai - 1. september. Frå og med 4. dag etter skade dekkjes ${spaceToNBSP(
                '2 000',
            )} kroner per dag i inntil 14 dagar.`,
            checkedRows: 1,
        },
        {
            title: `Skadar på ${hyphenAsSoftHyphen('drivstoff-system')}`,
            help: 'Dekkjer skade på drivstoffsystem som skyldes tilførsel av forureina drivstoff, til dømes dieseldyr eller vatn. I tillegg erstattes feilfylling.',
            checkedRows: 1,
        },
        {
            title: `Redning av båt med ${hyphenAsSoftHyphen('person-skade')}`,
            help: 'Dekkjer dokumenterte ekstrautgifter ved henting av båt etter personredning.',
            checkedRows: 1,
        },

        {
            title: `Joller med motor`,
            help: `Dekkjer jolle med motor med inntil ${spaceToNBSP('30 000')} kroner.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Fast-montert')} utstyr`,
            help: `Dekkjer fastmontert utstyr med inntil ${spaceToNBSP('10 000')} kroner.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Opplags-utstyr')}`,
            help: `Dekkjer opplagsutstyr som krybbe, stativ og presenning med inntil ${spaceToNBSP('15 000')} kroner.`,
            checkedRows: 1,
        },
    ],
};

export default batConfig;
