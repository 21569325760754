import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { spaceToNBSP } from '../../utils/spaceToNBSP';

const onlineConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Tap ved onlinekjøp',
            checkedRows: 1,
            help: `Tap ved onlinekjøp av varer og tjenester som du ikke mottar. Dekker inntil ${spaceToNBSP(
                '30 000',
            )} kroner i året.`,
        },
        {
            title: 'Tap ved onlinesalg',
            checkedRows: 1,
            help: `Tap ved onlinesalg av varer og tjenester i Norge hvis du ikke mottar betaling. Dekker inntil ${spaceToNBSP(
                '30 000',
            )} kroner i året.`,
        },
        {
            title: 'Nettmobbing og æreskrenkelser',
            checkedRows: 1,
            help: `Fjerning av elektronisk materiale etter nettmobbing eller æreskrenkelser. Dekker inntil ${spaceToNBSP(
                '30 000',
            )} kroner i året.`,
        },
        {
            title: 'Psykolog- eller rådgivningstimer',
            checkedRows: 1,
            help: '10 timer psykolog- eller rågivningstimer etter nettmobbing eller æreskrenkelser.',
        },
        {
            title: 'Gjenoppretting og rekonstruering',
            checkedRows: 1,
            help: `Gjenoppretting og rekonstruering av data og programvare på enheter utsatt for dataangrep. Dekker inntil ${spaceToNBSP(
                '30 000',
            )} kroner per år.`,
        },
    ],
};

export default onlineConfig;
