import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const barnConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Medisinsk invaliditet som skyldes sykdom eller ulykke',
            checkedRows: 1,
            help: 'Erstatning hvis barnet får nedsatt funksjonsevne på grunn av sykdom eller ulykke. Gir økt økonomisk spillerom for å møte behov og utgifter barnet får på grunn av funksjonsnedsettelsen. Erstatningen baseres på invaliditetsgrad og gir en utbetaling på inntil 25G ved medisinsk invaliditet og inntil 15G ved ulykke.',
        },
        {
            title: `${hyphenAsSoftHyphen('Arbeids-uførhet')} - Månedlig utbetaling ved uførhet`,
            checkedRows: 1,
            help: `Dekningen gir en månedlig utbetaling dersom barnet har vært minst ${spaceToNBSP(
                '50 %',
            )} sammenhengende arbeidsufør i 12 måneder. Arbeidsuførhet som skyldes psykiske lidelser, er unntatt. Forsikringen stiller ingen krav til varig arbeidsuførhet. Barnet får utbetaling tidligst ved fylte 18 år. Forsikringen opphører når barnet fyller 26 år.`,
        },
        {
            title: `${hyphenAsSoftHyphen('Arbeids-uførhet')} – ${hyphenAsSoftHyphen('Uføre-kapital')}`,
            checkedRows: 1,
            help: 'Uførekapital gir barnet en engangsutbetaling om barnet blir varig ufør. Psykiske lidelser er unntatt.',
        },
        {
            title: `Erstatning ved utvalgte ${hyphenAsSoftHyphen('syk-dommer')}/skader`,
            checkedRows: 1,
            help: 'Hvis barnet rammes av en alvorlig sykdom eller skade, kan livet og hverdagen bli snudd på hodet. Erstatningen på 3G kommer raskt til utbetaling etter stilt diagnose. Se den fulle listen under spørsmål og svar.',
        },
        {
            title: 'Økonomisk bistand til foresatte ved utvalgte sykdommer og skader',
            checkedRows: 1,
            help: 'Engangsutbetaling på 2G hvis barnet får en alvorlig sykdom eller skade. Utbetalingen kommer raskt etter stilt diagnose og kan gi foresatte økonomisk spillerom for å ta permisjon for å være mer med barnet eller dekke utgifter som kan oppstå. Se den fulle listen under spørsmål og svar.',
        },
        {
            title: `Tilpasning av bolig og tilskudd til tekniske ${hyphenAsSoftHyphen('hjelpe-midler')}`,
            checkedRows: 1,
            help: 'Dersom behovet for tekniske hjelpemidler eller ombygging av bolig oppstår på grunn av sykdom eller skade, dekkes inntil 2G per skadetilfelle.',
        },
        {
            title: `${hyphenAsSoftHyphen('Sykehus-opphold')}`,
            checkedRows: 1,
            help: 'Ved sykehusopphold på mer enn 9 dager, utbetaler forsikringen 600 kr per døgn fra første oppholdsdag. Utbetalingen gjelder i inntil ett år per skade- eller sykdomstilfelle.',
        },
        {
            title: `Behov for ${hyphenAsSoftHyphen('lang-varig')} pleie/omsorg – ${hyphenAsSoftHyphen(
                'hjelpe-stønad',
            )}`,
            checkedRows: 1,
            help: `Supplement til hjelpestønad innvilget/mottatt fra NAV. Behov for langvarig pleie og omsorg har ingen unntak.`,
        },
        {
            title: `${hyphenAsSoftHyphen('Døds-fall')}`,
            checkedRows: 1,
            help: 'Hvis barnet dør, betaler vi en engangserstatning på 1G. Erstatningen kan benyttes til å dekke utgifter i forbindelse med begravelse og annet. Erstatningen utbetales uansett årsak.',
        },
        {
            title: `${hyphenAsSoftHyphen('Behandlings-utgifter')} ved ulykke`,
            checkedRows: 1,
            help: 'Dekker behandlingsutgifter ved ulykke med inntil 1G i Norden. Gjelder for nødvendige utgifter som tannlege, medisinsk behandling og reiseutgifter.',
        },
    ],
};

export default barnConfig;
