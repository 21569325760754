import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const eikaplussConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Innbo Pluss',
            checkedRows: 1,
            help: 'Vår beste innboforsikring med ubegrenset forsikringssum.',
        },
        {
            title: 'Reise Pluss',
            checkedRows: 1,
            help: 'Den beste reiseforsikringen vår som gjelder fra sekundet du går ut døra hjemmefra.',
        },
        {
            title: `${hyphenAsSoftHyphen('Helse-forsikring')}`,
            checkedRows: 1,
            help: 'Hjelper deg til rask behandling og tilbake til en aktiv hverdag.',
        },
        {
            title: `${hyphenAsSoftHyphen('Verdi-gjenstand-forsikring')}`,
            checkedRows: 1,
            help: `Dekker skader på uspesifiserte verdigjenstander på inntil ${spaceToNBSP(
                '30 000',
            )} kroner to ganger i året.`,
        },
        {
            title: `${hyphenAsSoftHyphen('Online-forsikring')}`,
            checkedRows: 1,
            help: `Dekker svindel ved onlinekjøp- og salg på inntil ${spaceToNBSP('30 000')} kroner.`,
        },
        {
            title: `${hyphenAsSoftHyphen('Ulykkes-forsikring')}`,
            checkedRows: 1,
            help: 'Sikrer deg mot økonomiske bekymringer hvis du er i en ulykke.',
        },
    ],
};

export default eikaplussConfig;
