import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const atvConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Kasko',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Brann/tjuveri',
            description: 'Dekkjer enkelte skader',
            color: 'medium',
        },
        {
            title: 'Ansvar',
            description: 'Dekkjer berre det lovpålagde',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: 'Dekkjer uavgrensa beløp for skader på personar, og inntil 100 millionar kroner for skader på andre sine køyretøy, eigedom eller eigendelar. Skader på eigen ATV er ikkje dekt av ansvarsforsikringa. For å få dette dekt må du ha brann/tjuveri, som dekkjer nokre skadetilfelle, eller kaskoforsikring.',
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekkjer utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan for eksempel vere tvisteløysing ved kjøp og sal av ATV.`,
            checkedRows: 3,
        },
        {
            title: `Fører- og ${hyphenAsSoftHyphen('passasjer-ulykke')}`,
            help: `Gir erstatning ved personskade på førar og/eller passasjer.`,
            checkedRows: 3,
        },
        {
            title: 'Brann',
            help: 'Vi dekkjer skader ved brann, kortslutning i leidningsnett, lynnedslag og eksplosjon.',
            checkedRows: 2,
        },
        {
            title: 'Tyveri',
            help: 'Vi dekkjer skader ved tjuveri og forsøk på tjuveri av eller frå køyretøy, og skadeverk som følgje av forsøk på tjuveri.',
            checkedRows: 2,
        },
        {
            title: 'Bagasje',
            help: `Dekkjer bagasje med inntil ${spaceToNBSP('10 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Vei-hjelp')}`,
            help: 'Vi hjelper deg ved motorstopp og annan køyreteknisk driftsstopp som hindrar deg frå å køyre vidare. Dette inkluderer reparasjon på staden eller tauing til næraste verkstad. Du får òg vegassistanse om du går tom for drivstoff. Vegassistanse gjeld ikkje frå terreng.',
            checkedRows: 1,
        },
        {
            title: 'Skade på ATV',
            help: `Dekkjer skader ved samanstøyt, utforkøyring, velting, hærverk eller annan uforutsett hending eller eit uhell som skjer utan forvarsel på eigen ATV.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Sikkerheits-utstyr')}`,
            help: `Dekkjer sikkerheitsutstyr som sikkerheitsdress, hjelm, hanskar og støvlar.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Fast-montert')} utstyr`,
            help: `Dekkjer fastmontert tilleggsutstyr, som dekor og spesiallakk for inntil ${spaceToNBSP(
                '20 000',
            )} kroner. Erstatninga er avgrensa til ${spaceToNBSP('50 %')} av køyretøyets marknadsverdi.`,
            checkedRows: 1,
        },
    ],
};

export default atvConfig;
