import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { ForsikringProduktkoder } from '../types/ForsikringProduktkoder';
import atv from './bokmal/atv';
import barn from './bokmal/barn';
import bat from './bokmal/bat';
import bil from './bokmal/bil';
import bobil from './bokmal/bobil';
import campingvogn from './bokmal/campingvogn';
import drone from './bokmal/drone';
import eikapluss from './bokmal/eikapluss';
import elsparkesykkel from './bokmal/elsparkesykkel';
import fritidsbolig from './bokmal/fritidsbolig';
import helse from './bokmal/helse';
import hest from './bokmal/hest';
import hund from './bokmal/hund';
import hus from './bokmal/hus';
import innbo from './bokmal/innbo';
import katt from './bokmal/katt';
import kritisksykdom from './bokmal/kritisksykdom';
import moped from './bokmal/moped';
import motorsykkel from './bokmal/motorsykkel';
import online from './bokmal/online';
import reise from './bokmal/reise';
import snoscooter from './bokmal/snoscooter';
import tilhenger from './bokmal/tilhenger';
import traktor from './bokmal/traktor';
import ulykke from './bokmal/ulykke';
import ung from './bokmal/ung';
import veteranbil from './bokmal/veteranbil';
import nynorskAtv from './nynorsk/atv';
import nynorskBarn from './nynorsk/barn';
import nynorskBat from './nynorsk/bat';
import nynorskBil from './nynorsk/bil';
import nynorskBobil from './nynorsk/bobil';
import nynorskCampingvogn from './nynorsk/campingvogn';
import nynorskDrone from './nynorsk/drone';
import nynorskEikapluss from './nynorsk/eikapluss';
import nynorskElsparkesykkel from './nynorsk/elsparkesykkel';
import nynorskFritidsbolig from './nynorsk/fritidsbolig';
import nynorskHelse from './nynorsk/helse';
import nynorskHest from './nynorsk/hest';
import nynorskHund from './nynorsk/hund';
import nynorskHus from './nynorsk/hus';
import nynorskInnbo from './nynorsk/innbo';
import nynorskKatt from './nynorsk/katt';
import nynorskKritisksykdom from './nynorsk/kritisksykdom';
import nynorskMoped from './nynorsk/moped';
import nynorskMotorsykkel from './nynorsk/motorsykkel';
import nynorskOnline from './nynorsk/online';
import nynorskReise from './nynorsk/reise';
import nynorskSnoscooter from './nynorsk/snoscooter';
import nynorskTilhenger from './nynorsk/tilhenger';
import nynorskTraktor from './nynorsk/traktor';
import nynorskUlykke from './nynorsk/ulykke';
import nynorskUng from './nynorsk/ung';
import nynorskVeteranbil from './nynorsk/veteranbil';

import { isAfterYear2023 } from '../utils/isAfterYear2023';

export type ForsikringProducts =
    | 'atv'
    | 'barn'
    | 'bat'
    | 'bil'
    | 'bobil'
    | 'campingvogn'
    | 'drone'
    | 'eikapluss'
    | 'elsparkesykkel'
    | 'fritidsbolig'
    | 'helse'
    | 'hest'
    | 'hund'
    | 'hus'
    | 'innbo'
    | 'katt'
    | 'kritisksykdom'
    | 'moped'
    | 'motorsykkel'
    | 'online'
    | 'reise'
    | 'snoscooter'
    | 'tilhenger'
    | 'traktor'
    | 'ulykke'
    | 'ung'
    | 'veteranbil';

export const ForsikringProduktkode: Record<ForsikringProducts, ForsikringProduktkoder> = {
    atv: ForsikringProduktkoder.ATV,
    barn: ForsikringProduktkoder.BARN_OG_UNGDOM,
    bat: ForsikringProduktkoder.FRITIDSBAT,
    bil: ForsikringProduktkoder.BIL,
    bobil: ForsikringProduktkoder.BOBIL,
    campingvogn: ForsikringProduktkoder.TILHENGER,
    drone: ForsikringProduktkoder.DRONE,
    eikapluss: ForsikringProduktkoder.EIKA_PLUSS,
    elsparkesykkel: ForsikringProduktkoder.SMA_ELKJORETOY,
    fritidsbolig: ForsikringProduktkoder.FRITIDSBOLIG,
    helse: ForsikringProduktkoder.HELSEFORSIKRING_P16,
    hest: ForsikringProduktkoder.HEST,
    hund: ForsikringProduktkoder.HUND,
    hus: ForsikringProduktkoder.BOLIG,
    innbo: ForsikringProduktkoder.INNBO,
    katt: ForsikringProduktkoder.HUNDKATT,
    kritisksykdom: ForsikringProduktkoder.KRITISK_SYKDOM,
    moped: ForsikringProduktkoder.MOPED,
    motorsykkel: isAfterYear2023() ? ForsikringProduktkoder.MOTORSYKKEL_NY : ForsikringProduktkoder.MOTORSYKKEL,
    online: ForsikringProduktkoder.ONLINEFORSIKRING,
    reise: ForsikringProduktkoder.REISE,
    snoscooter: ForsikringProduktkoder.SNOSCOOTER,
    tilhenger: ForsikringProduktkoder.TILHENGER,
    traktor: ForsikringProduktkoder.TRAKTOR,
    ulykke: ForsikringProduktkoder.ULYKKE,
    ung: ForsikringProduktkoder.UNGDOM,
    veteranbil: ForsikringProduktkoder.VETERANKJORETOY,
};

type ComparisonTableLanguageType = Record<'nb' | 'nn', ComparisonTableProps>;
type ProductConfigType = Record<ForsikringProducts, ComparisonTableLanguageType>;

const productConfig: ProductConfigType = {
    atv: { nb: atv, nn: nynorskAtv },
    barn: { nb: barn, nn: nynorskBarn },
    bat: { nb: bat, nn: nynorskBat },
    bil: { nb: bil, nn: nynorskBil },
    bobil: { nb: bobil, nn: nynorskBobil },
    campingvogn: { nb: campingvogn, nn: nynorskCampingvogn },
    drone: { nb: drone, nn: nynorskDrone },
    eikapluss: { nb: eikapluss, nn: nynorskEikapluss },
    elsparkesykkel: { nb: elsparkesykkel, nn: nynorskElsparkesykkel },
    fritidsbolig: { nb: fritidsbolig, nn: nynorskFritidsbolig },
    helse: { nb: helse, nn: nynorskHelse },
    hest: { nb: hest, nn: nynorskHest },
    hund: { nb: hund, nn: nynorskHund },
    hus: { nb: hus, nn: nynorskHus },
    innbo: { nb: innbo, nn: nynorskInnbo },
    katt: { nb: katt, nn: nynorskKatt },
    kritisksykdom: { nb: kritisksykdom, nn: nynorskKritisksykdom },
    moped: { nb: moped, nn: nynorskMoped },
    motorsykkel: { nb: motorsykkel, nn: nynorskMotorsykkel },
    online: { nb: online, nn: nynorskOnline },
    reise: { nb: reise, nn: nynorskReise },
    snoscooter: { nb: snoscooter, nn: nynorskSnoscooter },
    tilhenger: { nb: tilhenger, nn: nynorskTilhenger },
    traktor: { nb: traktor, nn: nynorskTraktor },
    ulykke: { nb: ulykke, nn: nynorskUlykke },
    ung: { nb: ung, nn: nynorskUng },
    veteranbil: { nb: veteranbil, nn: nynorskVeteranbil },
};
export default productConfig;
