import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const bobilConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Bubil Pluss',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Kasko',
            description: 'Ei god bobilforsikring',
            color: 'medium',
        },
        {
            title: `${hyphenAsSoftHyphen('Del-kasko')}`,
            description: 'Eit rimelegare alternativ',
            color: 'light',
        },
        {
            title: 'Ansvar',
            description: 'Dekkjer berre det lovpålagde',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: 'Uavgrensa beløp for skadar påført personar, og inntil 100 millionar kroner for skadar på andre køyretøy, eigedom eller eigendelar. Skadar på eigen bubil dekkast ikkje av ansvarsforsikringa. For å få dette dekka må du enten ha delkasko, som dekkar nokre skadetilfelle, eller kaskoforsikring.',
            checkedRows: 4,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekkjer utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan til dømes vere tvisteløysing ved kjøp og sal av bil. `,
            checkedRows: 4,
        },
        {
            title: `Førar- og ${hyphenAsSoftHyphen('passasjer-ulykke')}`,
            help: 'Gjev erstatning ved personskade på førar og/eller passasjer.',
            checkedRows: 4,
        },
        {
            title: 'Brann',
            help: 'Vi dekkjer skadar ved brann, kortslutning i leidningsnett, lynnedslag og eksplosjon.',
            checkedRows: 3,
        },
        {
            title: 'Tjuveri',
            help: 'Vi dekkjer skadar ved tjuveri og forsøk på tjuveri av eller frå bubil, og skadeverk som følgje av forsøk på tjuveri.',
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Vei-hjelp')}`,
            help: 'Erstatter nødvendige utgifter til berging/taueing av bil til næraste verkstad, eller reparasjon på staden viss det er rimelegare og lar seg gjere. Dette gjeld ved vognskade, anna køyreteknisk driftsstopp som at det er tomt for drivstoff eller straum.',
            checkedRows: 3,
        },
        {
            title: 'Glas',
            help: `Vi dekkjer brotskadar på alle bubilens vindauge, også glasstak og takluker av glas. Kan ruta reparerast, betaler du ingen eigenandel inntil 750 kroner. Må ruta bytast er eigenandelen på ${spaceToNBSP(
                '5 000',
            )} kroner.`,
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Skade på bu-bil')}`,
            help: 'Dekkjer skadar ved samanstøyt, utforkøyring, velting, hærverk eller anna uforutsett hending eller eit ulukke som skjer utan forvarsel på eigen bubil.',
            checkedRows: 2,
        },
        {
            title: `Bagasje og eiendeler`,
            help: `Dekkjer skade på personlege eigendelar og bagasje som er i bubilen under køyreturen med inntil  ${spaceToNBSP(
                '20 000',
            )} kroner. Kan utvidast til ${spaceToNBSP('100 000')} kroner viss Bubil Pluss er valt.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Motor-skade')}`,
            help: `Dekkjer tilfeldig og plutseleg mekanisk skade på motor, girkasse/clutch, drivverk, motorens og automatgirkassas elektroniske hovudstyreeining, samt hybrid-/elbilers batteripakke. For Bubil Pluss kan ikkje bilen ha køyrt meir enn ${spaceToNBSP(
                '200 000',
            )} km eller vere eldre enn 10 år. For kasko kan ikkje bubilen ha køyrt meir enn ${spaceToNBSP(
                '60 000',
            )} eller vere eldre enn 6 år.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Fukt-skade')}`,
            help: 'Dekkjer fuktskade i vegger, tak og golv i bubilen inntil den er 15 år gammal. Krev årleg fuktsjekk utført av forhandlar eller verkstad.',
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Skade-dyr')}`,
            help: 'Dekkjer skadar påført av mus, insekt og andre skadedyr. Tilleggsutstyr og bagasje er også dekka dersom dette skadast samtidig som bubilen.',
            checkedRows: 1,
        },
        {
            title: 'Tap av nøkkel',
            help: `Erstatter mista eller øydelagt bilnøkkel med inntil ${spaceToNBSP(
                '15 000',
            )} kroner per skadetilfelle, og maksimalt to gonger per forsikringsår. Ingen eigenandel.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Feil-fylling av driv-stoff')}`,
            help: `Dekkjer tømming, rens av tank og reparasjon på inntil ${spaceToNBSP(
                '50 000',
            )} kroner ved feilfylling. Eigenandel på ${spaceToNBSP(
                '6 000',
            )} kroner. Større kostnader behandlast som kaskoskade og vil medføre eigenandel og bonustap.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Ferie-avbrot')}`,
            help: 'Ved ferieavbrot på grunn av skade som dekkast av forsikringa, refunderer vi overnattings- og leigebilutgifter med inntil 1500 kroner per dag i inntil 14 dagar.',
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Fast-montert tilleggs-utstyr')}`,
            help: `Dekkjer skadar på fastmontert tilleggsutstyr med inntil ${spaceToNBSP(
                '50 000',
            )}  ${spaceToNBSP('20 000')} kroner for Kasko.`,
            checkedRows: 1,
        },
    ],
};

export default bobilConfig;
