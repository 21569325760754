import { ComparisonTableProps, RowProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

import { isAfterYear2023 } from '../../utils/isAfterYear2023';

const additionalDekning: RowProps[] = isAfterYear2023()
    ? [
          {
              title: 'Tap av nøkkel',
              help: `Erstatter mistet eller ødelagt bilnøkkel med inntil ${spaceToNBSP(
                  '15 000',
              )} kroner per skadetilfelle, og maksimalt to ganger per forsikringsår. Ingen egenandel.`,
              checkedRows: 1,
          },
          {
              title: `${hyphenAsSoftHyphen('Feil-fylling av driv-stoff')}`,
              help: `Dekker tømming, rens av tank og reparasjon på inntil ${spaceToNBSP(
                  '50 000',
              )} kroner ved feilfylling. Egenandel på ${spaceToNBSP(
                  '6 000',
              )} kroner. Større kostnader behandles som kaskoskade og vil medføre egenandel og bonustap.`,
              checkedRows: 1,
          },
      ]
    : [];

const bobilConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Bobil Pluss',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Kasko',
            description: 'En god bobilforsikring',
            color: 'medium',
        },
        {
            title: `${hyphenAsSoftHyphen('Del-kasko')}`,
            description: 'Et rimeligere alternativ',
            color: 'light',
        },
        {
            title: 'Ansvar',
            description: 'Dekker kun det lovpålagte',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: `Ubegrenset beløp for skader påført personer, og inntil 100 millioner kroner for skader på andres kjøretøy, eiendom eller eiendeler. Skader på egen bobil dekkes ikke av ansvarsforsikringen. For å få dette dekket må du enten ha delkasko, som dekker noen skadetilfeller, eller kaskoforsikring.`,
            checkedRows: 4,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekker utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan for eksempel være tvisteløsning ved kjøp og salg av bil.`,
            checkedRows: 4,
        },
        {
            title: `Fører- og ${hyphenAsSoftHyphen('passasjer-ulykke')}`,
            help: 'Gir erstatning ved personskade på fører og/eller passasjer.',
            checkedRows: 4,
        },
        {
            title: 'Brann',
            help: 'Vi dekker skader ved brann, kortslutning i ledningsnett, lynnedslag og eksplosjon.',
            checkedRows: 3,
        },
        {
            title: 'Tyveri',
            help: 'Vi dekker skader ved tyveri og forsøk på tyveri av eller fra bobil, og skadeverk som følge av forsøk på tyveri.',
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Vei-hjelp')}`,
            help: `Erstatter nødvendige utgifter til berging/tauing av bil til nærmeste verksted, eller reparasjon på stedet hvis det er rimeligere og lar seg gjøre. Dette gjelder ved vognskade, annen kjøreteknisk driftsstopp som at det er tomt for drivstoff eller strøm.`,
            checkedRows: 3,
        },
        {
            title: 'Glass',
            help: `Vi dekker bruddskader på alle bobilens vinduer, også glasstak og takluker av glass. Kan ruten repareres, betaler du ingen egenandel på skader inntil 750 kroner. Må ruten byttes er egenandelen på ${spaceToNBSP(
                '5 000',
            )} kroner.`,
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Skade på bo-bil')}`,
            help: `Dekker skader ved sammenstøt, utforkjøring, velting, hærverk eller annen uforutsett hendelse eller et uhell som skjer uten forvarsel på egen bobil.`,
            checkedRows: 2,
        },
        {
            title: `Bagasje og eiendeler`,
            help: `Dekker skade på personlige eiendeler og bagasje som er i bobilen under kjøreturen med inntil ${spaceToNBSP(
                '20 000',
            )} kroner. Kan utvides til ${spaceToNBSP('100 000')} kroner hvis Bobil Pluss er valgt.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Motor-skade')}`,
            help: `Dekker tilfeldig og plutselig mekanisk skade på motor, girkasse/clutch, drivverk, motorens og automatgirkassens elektroniske hovedstyreenhet, samt hybrid-/elbilers batteripakke. For Bobil Pluss kan ikke bilen ha kjørt mer enn ${spaceToNBSP(
                '200 000',
            )} km eller være eldre enn 10 år. For kasko kan ikke bobilen ha kjørt mer enn ${spaceToNBSP(
                '60 000',
            )} km eller være eldre enn 6 år.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Fukt-skade')}`,
            help: `Dekker fuktskade i vegger, tak og gulv i bobilen inntil den er 15 år gammel. Krever årlig fuktsjekk utført av forhandler eller verksted.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Skade-dyr')}`,
            help: `Dekker skader påført av mus, insekter og andre skadedyr. Tilleggsutstyr og bagasje er også dekket dersom dette skades samtidig som bobilen.`,
            checkedRows: 1,
        },
        ...additionalDekning,
        {
            title: `${hyphenAsSoftHyphen('Ferie-avbrudd')}`,
            help: 'Ved ferieavbrudd på grunn av skade som dekkes av forsikringen, refunderer vi overnattings- og leiebilutgifter med inntil 1500 kroner per dag i inntil 14 dager.',
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Fast-montert tilleggs-utstyr')}`,
            help: `Dekker skader på fastmontert tilleggsutstyr med inntil ${spaceToNBSP(
                '50 000',
            )} kroner for Bobil Pluss og ${spaceToNBSP('20 000')} kroner for Kasko.`,
            checkedRows: 1,
        },
    ],
};

export default bobilConfig;
