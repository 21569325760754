import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const fritidsboligConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Hytte pluss',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Hytte Standard',
            description: 'En god hytteforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: `Brann-, vann- og ${hyphenAsSoftHyphen('tyveri-skader')}`,
            help: `Vi dekker brannskade på bygning og hageanlegg. Vi dekker også vannskader fra rørledning etter brudd, lekkasje eller oversvømmelse. Det gjelder også vanninntrenging fra terreng, grunn eller avløpsrør. Skader på bygning i forbindelse med innbrudd og tyveri.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Natur-skade')}`,
            help: 'Dekker naturskade som skyldes skred, storm, flom, stormflo, flodbølge, meteorittnedslag, jordskjelv eller vulkanutbrudd.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Du kan få dekket utgifter til rettshjelp, hvis du blir part i en rettslig tvist eller juridisk konflikt.`,
            checkedRows: 2,
        },
        {
            title: `Utett ${hyphenAsSoftHyphen('våt-rom')}`,
            help: 'Vi dekker følgeskader etter utett våtrom.',
            checkedRows: 2,
        },
        {
            title: 'Skader på småbygg',
            help: 'Bygninger på samme gårds- og bruksnummer som er nevnt i forsikringsbeviset er automatisk medforsikret i hytteforsikringen.',
            checkedRows: 2,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('hage-anlegg')}`,
            help: 'Dekker skader på hageanlegg opp til 5 dekar, som oppstår plutselig og uforutsett. Dette gjelder også for små bygninger på inntil 10 kvadratmeter. Dette inkluderer gjerde, flaggstang, parabolantenne, utvendig svømmebasseng, også badestamp og boblebad, med tilknyttede ledninger.',
            checkedRows: 2,
        },
        {
            title: 'Følgeskader ved håndverkerfeil',
            help: 'Følgeskader på bygning som følge av materialfeil, konstruksjonsfeil, prosjekteringsfeil eller uriktig montasje. Dette gjelder virksomhet med eget organisasjonsnummer. Forsikringen gjelder i inntil 10 år etter at arbeidet er ferdigstilt.',
            checkedRows: 1,
        },
        {
            title: 'Gjenoppføring av hytte',
            help: `Ved skade som er større enn ${spaceToNBSP(
                '75 %',
            )} av hva det koster å gjenoppføre hytte, kan du velge å få oppgjør som om hytten er totalskadet.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Sol-celle')}`,
            help: `Erstatter tapt strømproduksjon til eget bruk med inntil 50 kroner dagen hvis solcelleanlegget montert på bygget blir skadet. Dekker inntil 24 måneder tapt produksjon og maks ${spaceToNBSP('30 000')} kroner per skade.`,
            checkedRows: 1,
        },
    ],
};

export default fritidsboligConfig;
