import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { spaceToNBSP } from '../../utils/spaceToNBSP';

const onlineConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Tap ved onlinekjøp',
            checkedRows: 1,
            help: `Tap ved onlinekjøp av varer og tenester som du ikkje mottar. Dekkjer inntil ${spaceToNBSP(
                '30 000',
            )} kroner i året.`,
        },
        {
            title: 'Tap ved onlinesal',
            checkedRows: 1,
            help: `Tap ved onlinesal av varer og tenester i Noreg viss du ikkje mottar betaling. Dekkjer inntil ${spaceToNBSP(
                '30 000',
            )} kroner i året.`,
        },
        {
            title: 'Nettmobbing og æreskrenkingar',
            checkedRows: 1,
            help: `Fjerning av elektronisk materiale etter nettmobbing eller ærekrenkingar. Dekkjer inntil ${spaceToNBSP(
                '30 000',
            )} kroner i året.`,
        },
        {
            title: 'Psykolog- eller rådgivningstimar',
            checkedRows: 1,
            help: '10 timar psykolog- eller rådgjevingstimar etter nettmobbing eller ærekrenkingar.',
        },
        {
            title: 'Gjenoppretting og rekonstruering',
            checkedRows: 1,
            help: `Gjenoppretting og rekonstruering av data og programvare på einingar utsett for dataangrep. Dekkjer inntil ${spaceToNBSP(
                '30 000',
            )} kroner per år.`,
        },
    ],
};

export default onlineConfig;
