import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

const ulykkeConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Utvidet ulukke',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Ulukke Standard',
            description: 'Ei god ulykkesforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: 'Ulukke',
            help: 'Kan dekkje behandlingsutgiftene dine viss du har vore i ei ulykke og gir erstatning ved varig skade eller død.',
            checkedRows: 2,
        },
        {
            title: 'Medisinsk invaliditet',
            help: 'Erstatning ved varig medisinsk invaliditet.',
            checkedRows: 2,
        },
        {
            title: 'Dødsfallerstatning',
            help: `Erstatning til ektefelle, sambuar eller arvingar viss du døyr i ei ulykke.`,
            checkedRows: 2,
        },
        {
            title: 'Utvida dekning ved utvalde fritidsaktivitetar',
            help: 'Forsikringa kan utvidast til å dekkje fjellklatring, kiting og andre sports- og fritidsaktivitetar.',
            checkedRows: 1,
        },
    ],
};

export default ulykkeConfig;
