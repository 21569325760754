import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const motorsykkelConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Kasko',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Brann/tyveri',
            description: 'Dekkjer enkelte skadar',
            color: 'medium',
        },
        {
            title: 'Ansvar',
            description: 'Dekkjer kun det lovpålagte',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: 'Ansvarsforsikringa dekkjer skade på personar, utan beløpsgrense, og skadar på andres køyretøy, bygningar og gjenstandar med inntil 100 millionar kroner. Skadar på eigen motorsykkel er ikkje dekt av ansvarsforsikringa. For å få dette dekka må du enten ha brann/tjuveri, som dekkjer nokre skadetilfelle, eller kaskoforsikring.',
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekkjer utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan for eksempel vere tvisteløysing ved kjøp og sal av motorsykkel.`,
            checkedRows: 3,
        },
        {
            title: `Førar- og ${hyphenAsSoftHyphen('passasjer-ulukke')}`,
            help: 'Gir erstatning ved personskade på førar og/eller passasjer.',
            checkedRows: 3,
        },
        {
            title: 'Brann',
            help: 'Vi dekkjer skadar ved brann, kortslutning i leidningsnett, lynnedslag og eksplosjon.',
            checkedRows: 2,
        },
        {
            title: 'Tjuveri',
            help: 'Vi dekkjer skadar ved tjuveri og forsøk på tjuveri av eller frå køyretøy, og skadeverk som følgje av forsøk på tjuveri.',
            checkedRows: 2,
        },
        {
            title: 'Bagasje',
            help: `Dekkjer bagasje inntil ${spaceToNBSP('10 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Sikkerheits-utstyr')}`,
            help: `Dekkjer sikkerheitsutstyr som sikkerheitsdress, hjelm, hanskar og støvlar.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Veg-hjelp')}`,
            help: 'Vi hjelper deg ved motorstopp og annan køyreteknisk driftsstopp som hindrar deg frå å køyre vidare. Dette inkluderer reparasjon på staden eller tauing til næraste verkstad. Du får også veghjelp viss du går tom for drivstoff.',
            checkedRows: 1,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('motor-sykkel')}`,
            help: 'Dekkjer skadar ved samanstøyt, utforkøyring, velt, hærverk eller annan uforutsett hending eller eitt uhell som skjer utan forvarsel på eigen motorsykkel.',
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Leige-bil')}/-MC`,
            help: 'Dekkjer kostnadene ved leige av ein erstatningsbil/MC når motorsykkelen er ute av drift på grunn av ei forsikringsrelatert hending, for eksempel ein kollisjon eller tjuveri.',
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Fast-montert')} tilleggsutstyr`,
            help: `Dekkjer fastmontert tilleggsutstyr, som dekor og spesiallakk for inntil ${spaceToNBSP(
                '20 000',
            )} kroner. Erstatninga er avgrensa til ${spaceToNBSP('50 %')} av køyretøyets marknadsverdi.`,
            checkedRows: 1,
        },
    ],
};

export default motorsykkelConfig;
