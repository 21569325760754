import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { spaceToNBSP } from '../../utils/spaceToNBSP';

const reiseConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Reise pluss',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Reise',
            description: 'Ei god reiseforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: 'Ulukke',
            help: 'Kan dekkje behandlingsutgifter etter ulukke og gi erstatning ved varig invaliditet eller død.',
            checkedRows: 2,
        },
        {
            title: 'Enkeltgjenstandar',
            help: `Dekkjer enkeltgjenstandar du har med deg på reise med inntil ${spaceToNBSP(
                '15 000',
            )} kroner viss dei blir stole, øydelagde av andre eller blir skada eller øydelagde i trafikkuhell, brann, på grunn av vasskade eller naturskade.`,
            checkedRows: 2,
        },
        {
            title: 'Sykkel',
            help: `Viss sykkelen din blir skada eller går tapt dekkjer vi inntil ${spaceToNBSP('10 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Heimtransport og behandling',
            help: 'Viss du blir sjuk på reise dekkjer vi heimreisa og behandlinga di.',
            checkedRows: 2,
        },
        {
            title: 'Bagasje',
            help: `Viss du har innsjekka bagasje og han går tapt eller blir forseinka dekkjer vi inntil ${spaceToNBSP(
                '6 000',
            )} kroner til kjøp av nødvendige erstatningar og inntil ${spaceToNBSP(
                '15 000',
            )} kroner i erstatning viss han går tapt.`,
            checkedRows: 2,
        },
        {
            title: 'Avbestilling',
            help: `Viss du eller nær familie blir sjuk eller reisa må avbrytast på grunn av til dømes naturhendingar, erstattar vi reiseutgiftene dine som du ikkje får refundert med inntil ${spaceToNBSP(
                '50 000',
            )} kroner per tilfelle.`,
            checkedRows: 2,
        },
        {
            title: 'Forsinking',
            help: 'Viss noko uforutsett skjer og du blir forsinka på reise dekkjer vi utgifter for overnatting og å ta igjen reiseplanen din.',
            checkedRows: 2,
        },
        {
            title: 'Enkeltgjenstandar',
            help: `Utvida dekning for enkeltgjenstandar. Viss enkeltgjenstandar du har med deg på reise blir stole, øydelagde av andre eller blir skada eller øydelagde i trafikkuhell, brann, etter vasskade eller naturskade, erstattar vi med inntil ${spaceToNBSP(
                '40 000',
            )} kroner.`,
            checkedRows: 1,
        },
        {
            title: 'Sykkel',
            help: `Utvida sum for skade eller tap av sykkel. Viss sykkelen din blir skada eller går tapt dekkjer vi inntil ${spaceToNBSP(
                '40 000',
            )} kroner.`,
            checkedRows: 1,
        },
        {
            title: 'Ingen eigenandel ved skade',
            help: 'Du betalar ingen eigenandel viss det skjer ei skade.',
            checkedRows: 1,
        },
        {
            title: 'Bagasje - uavgrensa sum',
            help: `Viss du har innsjekka bagasje og han går tapt eller blir forseinka dekkjer vi inntil ${spaceToNBSP(
                '6 000',
            )} kroner til kjøp av nødvendige erstatningar og heile verdien i erstatning viss den går tapt.`,
            checkedRows: 1,
        },
        {
            title: 'Avbestilling - uavgrensa sum',
            help: 'Viss du eller nær familie blir sjuk eller reisa må avbrytast på grunn av til dømes naturhendingar, erstattar vi heile summen av reiseutgiftene dine som du ikkje får refundert.',
            checkedRows: 1,
        },
        {
            title: 'Actionfylte aktivitetar',
            help: 'Ulykker som skjer mens du driv med fjellklatring, skisport, kiting og downhillkøyring på sykkel blir dekka.',
            checkedRows: 1,
        },
    ],
};

export default reiseConfig;
