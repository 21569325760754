import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const eikaplussConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Innbu Pluss',
            checkedRows: 1,
            help: 'Vår beste innbuforsikring med uavgrensa forsikringssum.',
        },
        {
            title: 'Reise Pluss',
            checkedRows: 1,
            help: 'Den beste reiseforsikringa vår som gjeld frå sekundet du går ut døra heimefrå.',
        },
        {
            title: `${hyphenAsSoftHyphen('Helse-forsikring')}`,
            checkedRows: 1,
            help: 'Hjelper deg til rask behandling og tilbake til ein aktiv kvardag.',
        },
        {
            title: `${hyphenAsSoftHyphen('Verdi-gjenstand-forsikring')}`,
            checkedRows: 1,
            help: `Dekkjer skadar på uspesifiserte verdigjenstandar på inntil ${spaceToNBSP(
                '30 000',
            )} kroner to gonger i året.`,
        },
        {
            title: `${hyphenAsSoftHyphen('Online-forsikring')}`,
            checkedRows: 1,
            help: `Dekkjer svindel ved onlinekjøp- og sal på inntil ${spaceToNBSP('30 000')} kroner.`,
        },
        {
            title: `${hyphenAsSoftHyphen('Ulukkes-forsikring')}`,
            checkedRows: 1,
            help: 'Sikrar deg mot økonomiske bekymringar viss du er i ei ulukke.',
        },
    ],
};

export default eikaplussConfig;
