import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const bilConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Bil Pluss',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Kasko',
            description: 'Nyare bil med høg verdi',
            color: 'medium',
        },
        {
            title: `${hyphenAsSoftHyphen('Del-kasko')}`,
            description: 'Eldre bil med lågere verdi',
            color: 'light',
        },
        {
            title: 'Ansvar',
            description: 'Berre det lovpålagde',
        },
    ],
    rows: [
        {
            title: `Ansvar og ${hyphenAsSoftHyphen('retts-hjelp')}`,
            help: `Uavgrensa beløp for skader på personar, og inntil 100 millionar kroner for skader på andre sine køyretøy, eigedom eller eigendelar. Skader på eigen bil er ikkje dekt av ansvarsforsikringa. For å få dette dekt må ein ha delkasko, som dekkjer nokre skadetilfelle, eller kaskoforsikring. Ein kan få dekt utgifter til rettshjelp på inntil ${spaceToNBSP(
                '100 000',
            )} kroner, om ein blir part i ei tvist, til dømes ved kjøp og sal av bil.`,
            checkedRows: 4,
        },
        {
            title: `Førar- og ${hyphenAsSoftHyphen('passasjer-ulykke')}`,
            help: 'Gir erstatning ved personskade på førar og/eller passasjer.',
            checkedRows: 4,
        },
        {
            title: 'Brann, glas og tjuveri',
            help: 'Vi dekkjer skader som følgje av brann, kortslutning i leidningsnett, lynnedslag og eksplosjon. I tillegg dekkjer vi brotskader på alle bilens vindauge, også glasstak og takluker av glass. Du betaler ingen eigenandel om glasruta kan reparerast. Om ruta må bytast, betaler du ein eigenandel på 3000 kroner. Vi dekkjer tjuveri og forsøk på tjuveri av eller frå bil, og skadeverk som følgje av forsøk på tjuveri.',
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Vei-hjelp')}`,
            help: 'Vi hjelper deg ved motorstopp og annan køyreteknisk driftsstopp som hindrar deg frå å køyre vidare. Dette inkluderer reparasjon på staden eller tauing av bil til næraste verkstad. Du får òg vegassistanse om du går tom for drivstoff eller straum. Gyldig i heile Europa.',
            checkedRows: 3,
        },
        {
            title: 'Skade på bil',
            help: 'Dekkjer skader ved samanstøyt, utforkøyring, velt, hærverk eller anna uforutsett hending eller uhell som skjer utan forvarsel på eige køyretøy.',
            checkedRows: 2,
        },
        {
            title: 'Bagasje og egiendelar',
            help: `Dekkjer skade på personlege eigendelar og bagasje som er i bilen under køyreturen. Det kan omfatte ulike typar gjenstandar som klede, toalettsaker, elektronikk, matvarer, sportsutstyr og verktøy. Med Bil Pluss får du dekt inntil ${spaceToNBSP(
                '30 000',
            )} kroner, og med Kasko får du dekt inntil ${spaceToNBSP('20 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Leigebil',
            help: 'Dekkjer kostnadar ved leigebil når bilen din er til reparasjon eller ikkje er i køyrebar stand etter ei skade. Med Bil Pluss får du leigebil inntil 45 dagar. For Kasko kan dette kjøpast som eit tillegg i inntil 10 dagar.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Motor-skade')}`,
            help: `Dekkjer tilfeldig og plutseleg mekanisk skade på motor, girkasse/clutch, drivverk, motorens og automatgirkassens elektroniske hovudstyreeining, samt batteripakke for hybrid-/elbilar. For Bil Pluss kan bilen ikkje ha køyrt meir enn ${spaceToNBSP(
                '200 000',
            )} km eller vere eldre enn 10 år. For Kasko kan bilen ikkje ha køyrt meir enn ${spaceToNBSP(
                '60 000',
            )} km eller vere eldre enn 6 år.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Tilleggs-utstyr')}`,
            help: `Dekkjer skader på tilleggsutstyr eller tilbehøyr som er installert i eller på bilen, i tillegg til standardkomponentane som følgjer med bilen. Ekstrautstyr kan vere alt frå lykter, felgar, stereoanlegg, navigasjonssystem, takstativ, spoiler, ekstra lys og liknande. For Bil Pluss gjeld dekking for inntil ${spaceToNBSP(
                '50 000',
            )} kroner og for kasko for inntil ${spaceToNBSP('20 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Nybil-garanti')}`,
            help: `Gir deg rett til ny bil av same merke, modell og årgang dersom reparasjonskostnadane overstig ${spaceToNBSP(
                '80 %',
            )} av samla kjøpesum. Med Bil Pluss kan bilen ikkje ha køyrt meir enn ${spaceToNBSP(
                '100 000',
            )} km eller vere eldre enn 3 år. For Kasko kan bilen ikkje ha køyrt meir enn ${spaceToNBSP(
                '15 000',
            )} km eller vere eldre enn eitt år.`,
            checkedRows: 2,
        },
        {
            title: 'Tap av nøkkel',
            help: `Erstattar tapt eller øydelagd bilnøkkel med inntil ${spaceToNBSP(
                '15 000',
            )} kroner per skadetilfelle, og maksimalt to gonger per forsikringsår. Ingen eigenandel.`,
            checkedRows: 1,
        },
        {
            title: 'Feilfylling av drivstoff',
            help: `Dekkjer tømming, reinsing av tank og reparasjon på inntil ${spaceToNBSP(
                '50 000',
            )} kroner ved feilfylling. Eigenandel på ${spaceToNBSP(
                '8 000',
            )} kroner. Større kostnader blir behandla som kaskoskade og vil medføre eigenandel og bonustap.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Parkerings-skade')} utan bonustap`,
            help: `Om bilen din blir skada når den står parkert, dekkjer vi reparasjon på inntil ${spaceToNBSP(
                '40 000',
            )} kroner utan bonustap. Gjeld til bilen er 10 år gammal.`,
            checkedRows: 1,
        },
    ],
};

export default bilConfig;
