import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { spaceToNBSP } from '../../utils/spaceToNBSP';

const hundConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Hund Pluss',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Hund',
            description: 'Ei god hundeforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: 'Veterinærutgifter',
            help: `Dekkjer utgifter til medisinar og preparat som veterinæren anbefaler.`,
            checkedRows: 2,
        },
        {
            title: 'Atopi/allergi',
            help: `Dekkjer utgifter knytt til allergi og atopi inntil ${spaceToNBSP(
                '5 000',
            )} kroner, blir utvida til ${spaceToNBSP('15 000')} kroner for Hund Pluss. `,
            checkedRows: 2,
        },
        {
            title: 'MR/CT',
            help: `Dekkjer utgiftene for nødvendig MR og CT inntil ${spaceToNBSP(
                '5 000',
            )} kroner per år, blir utvida til ${spaceToNBSP('10 000')} kroner med Hund Pluss.`,
            checkedRows: 2,
        },
        {
            title: 'Bruksverdi',
            help: 'Valfritt tillegg som lèt deg forsikra bruksverdien til hunden din om han er trena for eit spesifikt bruksområde viss han mistar bruksverdien',
            checkedRows: 2,
        },
        {
            title: 'Liv',
            help: 'Valfritt tillegg som dekkjer kostnaden for å erstatta hunden om han skulle døy eller komma bort.',
            checkedRows: 2,
        },
        {
            title: 'Kvalpekullforsikring',
            help: 'Valfritt tillegg som dekkjer livsforsikring og veterinærdekning for kvalpekull. Gyldig frå kvalpane er 5 veker gamle til dei blir 15 veker eller dagen etter levering til ny eigar. Dekkje også skjulte feil og manglar.',
            checkedRows: 2,
        },
        {
            title: 'Vanntrening/rehabilitering',
            help: `Dekkjer utgifter for rehabilitering av hunden etter sjukdom eller skade med inntil ${spaceToNBSP(
                '10 000',
            )} kroner per år.`,
            checkedRows: 1,
        },
        {
            title: 'Valpeforsikring',
            help: 'Dekkjer kvalpen sine veterinærkostnader inntil summen du vel fram til kvalpen er 12 dagar gammal.',
            checkedRows: 1,
        },
        {
            title: 'Tannsjukdommar',
            help: 'Dekkjer tannsjukdommar, tannkjøttsjukdommar, trekking av tilbakehaldne mjølketenner og tannstillingsfeil som medfører medisinske problem.',
            checkedRows: 1,
        },
    ],
};

export default hundConfig;
