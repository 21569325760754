import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const mopedConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Kasko',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Brann/tyveri',
            description: 'Dekkjer enkelte skader',
            color: 'medium',
        },
        {
            title: 'Ansvar',
            description: 'Dekkjer kun det lovpålagte',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: 'Dekkjer uavgrensa beløp for skadar på personar, og inntil 100 millionar kroner for skadar på andres køyretøy, eigedom eller eigendelar. Skadar på eigen moped eller scooter er ikkje dekt av ansvarsforsikringa. For å få dette dekka må du enten ha brann/tyveri, som dekkjer nokre skadetilfelle, eller kaskoforsikring.',
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekkjer utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan for eksempel vere tvisteløysing ved kjøp og sal av moped eller scooter.`,
            checkedRows: 3,
        },
        {
            title: `Førar- og ${hyphenAsSoftHyphen('passasjer-ulukke')}`,
            help: `Gir erstatning ved personskade på førar og/eller passasjer.`,
            checkedRows: 3,
        },
        {
            title: 'Brann',
            help: 'Vi dekkjer skadar ved brann, kortslutning i leidningsnett, lynnedslag og eksplosjon.',
            checkedRows: 2,
        },
        {
            title: 'Tjuveri',
            help: 'Vi dekkjer også tjuveri og forsøk på tjuveri av eller frå moped, scooter og mopedbil, og skadeverk som følgje av forsøk på tjuveri.',
            checkedRows: 2,
        },
        {
            title: 'Bagasje',
            help: `Dekkjer bagasje med inntil ${spaceToNBSP('10 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('køyre-tøy')}`,
            help: 'Dekkjer skadar ved samanstøyt, utforkøyring, velt, hærverk eller annan uforutsett hending eller eitt uhell som skjer utan forvarsel på eigen moped, scooter og mopedbil.',
            checkedRows: 1,
        },
    ],
};

export default mopedConfig;
