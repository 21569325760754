import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const traktorConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: `${hyphenAsSoftHyphen('Maskin-skade')}`,
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Kasko',
            description: 'Dekkjer dei fleste skadar',
            color: 'medium',
        },
        {
            title: 'Brann/tjuveri',
            description: 'Dekkjer enkelte skadar',
            color: 'light',
        },
        {
            title: 'Ansvar',
            description: 'Dekkjer berre det lovpålagde',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: 'Dekkjer uavgrensa beløp for skader på personar, og inntil 100 millionar kroner for skader på andre sitt køyretøy, eigedom eller eigendelar. Skader på eigen traktor er ikkje dekka av ansvarsforsikringa. For å få dette dekka må du enten ha brann/tyveri, som dekkjer nokre skadetilfelle, eller kaskoforsikring.',
            checkedRows: 4,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekkjer utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan for eksempel vere tvisteløysing ved kjøp og sal av traktor.`,
            checkedRows: 4,
        },
        {
            title: `Førar- og ${hyphenAsSoftHyphen('passasjer-ulukke')}`,
            help: 'Gir erstatning ved personskade på førar og/eller passasjer.',
            checkedRows: 4,
        },
        {
            title: 'Brann',
            help: 'Vi dekkjer skadar ved brann, kortslutning i leidningsnett, lynnedslag og eksplosjon.',
            checkedRows: 3,
        },
        {
            title: 'Tjuveri',
            help: 'Vi dekkjer skader ved tjuveri og forsøk på tjuveri av eller frå traktor, og skadeverk som følgje av forsøk på tjuveri.',
            checkedRows: 3,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('privat-traktor')}`,
            help: 'Dekkjer skader ved samanstøyt, utforkøyring, velting, hærverk eller anna uforutsett hending eller eit uhell som skjer utan forvarsel på eigen traktor.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Sikkerheits-utstyr')}`,
            help: 'Dekkjer sikkerheitsutstyr som høyrer til traktoren.',
            checkedRows: 2,
        },
        {
            title: 'Skade på tilbehøyr',
            help: 'Dekkjer skader på tilbehøyr som har naturleg samanheng med traktoren sin bruk.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Fast-montert tilleggs-utstyr')}`,
            help: `Dekkjer fastmontert tilleggsutstyr med inntil ${spaceToNBSP('60 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Motor-skade')}`,
            help: 'Dekkjer tilfeldig og plutseleg mekanisk skade på motor, girkasse/clutch, drivverk, motoren og automatgirkassa sin elektroniske hovudstyreeining.',
            checkedRows: 1,
        },
    ],
};

export default traktorConfig;
