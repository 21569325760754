import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const tilhengerConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Kasko',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Brann/tyveri',
            description: 'Dekker enkelte skader',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekker utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan for eksempel være tvisteløsning ved kjøp og salg av tilhenger.`,
            checkedRows: 2,
        },
        {
            title: 'Brann og tyveri',
            help: `Vi dekker skader ved brann, kortslutning i ledningsnett, lynnedslag og eksplosjon. Vi dekker også tyveri og forsøk på tyveri av eller fra tilhenger, og skadeverk som følge av forsøk på tyveri.`,
            checkedRows: 2,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('til-henger')}`,
            help: `Dekker skader ved sammenstøt, utforkjøring, velting, hærverk eller annen uforutsett hendelse eller uhell som skjer uten forvarsel på egen tilhenger.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Sikkerhets-utstyr')}`,
            help: 'Dekker sikkerhetsutstyr som tilhører tilhengeren.',
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Fast-montert tilleggs-utstyr')}`,
            help: `Dekker fastmontert tilleggsutstyr, som dekor og spesiallakk for inntil ${spaceToNBSP(
                '20 000',
            )} kroner. Erstatningen er begrenset til ${spaceToNBSP('50 %')} av tilhengerens markedsverdi.`,
            checkedRows: 1,
        },
    ],
};

export default tilhengerConfig;
