import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const campingvognConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: `${hyphenAsSoftHyphen('Camping-vogn')} Pluss`,
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Kasko',
            description: 'Dekker de fleste skader',
            color: 'medium',
        },
        {
            title: 'Brann/tyveri',
            description: 'Dekker enkelte skader',
        },
    ],
    rows: [
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekker utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan for eksempel være tvisteløsning ved kjøp og salg av kjøretøy.`,
            checkedRows: 3,
        },
        {
            title: 'Brann og tyveri',
            help: 'Vi dekker skader ved brann, kortslutning i ledningsnett, lynnedslag og eksplosjon. Vi dekker også tyveri og forsøk på tyveri av eller fra campingvogn, og skadeverk som følge av forsøk på tyveri.',
            checkedRows: 3,
        },
        {
            title: 'Glass',
            help: `Dekker bruddskader på alle vognens vinduer. Kan ruten repareres, betaler du ingen egenandel inntil 750 kroner. Må ruten byttes er egenandelen på ${spaceToNBSP('3 000')} kroner.`,

            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Vei-hjelp')}`,
            help: 'Vi hjelper deg ved motorstopp og annen kjøreteknisk driftsstopp som hindrer deg fra å kjøre videre. Dette inkluderer reparasjon på stedet eller tauing til nærmeste verksted. Du får også veihjelp hvis du går tom for drivstoff eller strøm.',
            checkedRows: 2,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('camping-vogn')}`,
            help: 'Dekker skader ved sammenstøt, utforkjøring, velting, hærverk eller annen uforutsett hendelse eller uhell som skjer uten forvarsel på egen campingvogn.',
            checkedRows: 2,
        },
        {
            title: 'Fastmontert tilbehør',
            help: `Når fortelt/spikertelt er medforsikret dekkes også platting med fastmontert tilbehør med inntil ${spaceToNBSP(
                '50 000',
            )} kroner på Campingvogn Pluss. På Kasko dekkes inntil ${spaceToNBSP('30 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Fastmontert tilleggsutstyr',
            help: `Fastmontert tilleggsutstyr dekkes med inntil ${spaceToNBSP(
                '50 000',
            )} kroner for Campingvogn Pluss. For Kasko dekkes inntil ${spaceToNBSP('20 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Bagasje og eiendeler',
            help: `Dekker bagasje og personlige eiendeler med inntil ${spaceToNBSP('20 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Fuktskader',
            help: `Dekker fuktskade i vegger, tak og gulv i campingvognen inntil den er 15 år gammel. Krever årlig fuktsjekk utført av forhandler eller verksted.`,
            checkedRows: 1,
        },
        {
            title: 'Skadedyr',
            help: `Dekker skader påført av mus, insekter og andre skadedyr. Tilleggsutstyr og bagasje er også dekket dersom dette skades samtidig som campingvognen.`,
            checkedRows: 1,
        },
        {
            title: 'Ferieavbrudd',
            help: `Ved ferieavbrudd på grunn av skade som dekkes av forsikringen, refunderer vi overnattings- og leiebilutgifter med inntil ${spaceToNBSP(
                '1 500',
            )} kroner per dag i inntil 14 dager.`,
            checkedRows: 1,
        },
    ],
};

export default campingvognConfig;
