import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const hestConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Liv',
            checkedRows: 1,
            help: 'Gir erstatning hvis hesten din dør eller blir borte. Dekker også utgiftene hvis hesten må avlives på grunn av ulykke eller skade.',
        },
        {
            title: `${hyphenAsSoftHyphen('Bruks-verdi')}`,
            checkedRows: 1,
            help: `Hvis hesten din er trent til et bestemt formål eller er avlsdyr kan du få erstatning hvis hesten mister bruksverdien sin.`,
        },
        {
            title: `${hyphenAsSoftHyphen('Veterinær-utgifter')}`,
            checkedRows: 1,
            help: `Dekker veterinærutgiftene til hesten. Kan utvides til å dekke føll under forsikret hoppe frem til føllet er 30 dager og til å dekke utgifter til foreskrevne medisiner og forbindingsmateriell med inntil ${spaceToNBSP(
                '4 000',
            )} kroner per skadetilfelle.`,
        },
        {
            title: `${hyphenAsSoftHyphen('Nød-slakt')}`,
            checkedRows: 1,
            help: `Hvis hesten dør eller må avlives ertsatter vi utgifter til nødslakt, bortkjøring og destruksjon av kadaver med inntil ${spaceToNBSP(
                '3 000',
            )} kroner.`,
        },
    ],
};

export default hestConfig;
