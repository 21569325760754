import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const hestConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Liv',
            checkedRows: 1,
            help: 'Gir erstatning viss hesten din døyr eller blir borte. Dekkjer også utgiftene viss hesten må avlivast på grunn av ulykke eller skade.',
        },
        {
            title: `${hyphenAsSoftHyphen('Bruks-verdi')}`,
            checkedRows: 1,
            help: `Viss hesten din er trena til eit bestemt formål eller er avlsdyr kan du få erstatning viss hesten mistar bruksverdien sin.`,
        },
        {
            title: `${hyphenAsSoftHyphen('Veterinær-utgifter')}`,
            checkedRows: 1,
            help: `Dekkjer veterinærutgiftene til hesten. Kan utvidast til å dekkja føl under forsikra hoppe fram til følet er 30 dagar og til å dekkja utgifter til anbefalte medisinar og forbindingsmateriell med inntil ${spaceToNBSP(
                '4 000',
            )} kroner per skadetilfelle.`,
        },
        {
            title: `${hyphenAsSoftHyphen('Nød-slakt')}`,
            checkedRows: 1,
            help: `Viss hesten døyr eller må avlivast ertsatter me utgifter til nødslakt, bortkøyring og destruksjon av kadaver med inntil ${spaceToNBSP(
                '3 000',
            )} kroner.`,
        },
    ],
};

export default hestConfig;
