import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const traktorConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: `${hyphenAsSoftHyphen('Maskin-skade')}`,
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Kasko',
            description: 'Dekker de fleste skader',
            color: 'medium',
        },
        {
            title: 'Brann/tyveri',
            description: 'Dekker enkelte skader',
            color: 'light',
        },
        {
            title: 'Ansvar',
            description: 'Dekker kun det lovpålagte',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: `Dekker ubegrenset beløp for skader på personer, og inntil 100 millioner kroner for skader på andres kjøretøy, eiendom eller eiendeler. Skader på egen traktor er ikke dekket av ansvarsforsikringen. For å få dette dekket må du enten ha brann/tyveri, som dekker noen skadetilfeller, eller kaskoforsikring.`,
            checkedRows: 4,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekker utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan for eksempel være tvisteløsning ved kjøp og salg av traktor.`,
            checkedRows: 4,
        },
        {
            title: `Fører- og ${hyphenAsSoftHyphen('passasjer-ulykke')}`,
            help: 'Gir erstatning ved personskade på fører og/eller passasjer.',
            checkedRows: 4,
        },
        {
            title: 'Brann',
            help: 'Vi dekker skader ved brann, kortslutning i ledningsnett, lynnedslag og eksplosjon.',
            checkedRows: 3,
        },
        {
            title: 'Tyveri',
            help: 'Vi dekker skader ved tyveri og forsøk på tyveri av eller fra traktor, og skadeverk som følge av forsøk på tyveri.',
            checkedRows: 3,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('privat-traktor')}`,
            help: `Dekker skader ved sammenstøt, utforkjøring, velting, hærverk eller annen uforutsett hendelse eller et uhell som skjer uten forvarsel på egen traktor.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Sikkerhets-utstyr')}`,
            help: 'Dekker sikkerhetsutstyr som tilhører traktoren.',
            checkedRows: 2,
        },
        {
            title: 'Skade på tilbehør',
            help: `Dekker skader på tilbehør som har naturlig sammenheng med båtens bruk.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Fast-montert tilleggs-utstyr')}`,
            help: `Dekker fastmontert tilleggsutstyr med inntil ${spaceToNBSP('60 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Motor-skade')}`,
            help: `Dekker tilfeldig og plutselig mekanisk skade på motor, girkasse/clutch, drivverk, motorens og automatgirkassens elektroniske hovedstyreenhet.`,
            checkedRows: 1,
        },
    ],
};

export default traktorConfig;
