import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { spaceToNBSP } from '../../utils/spaceToNBSP';

const elsparkesykkelConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Skader på deg selv eller andre',
            checkedRows: 1,
            help: 'Ubegrenset beløp for skader på personer.',
        },
        {
            title: 'Skader på andres eiendom eller eiendeler',
            checkedRows: 1,
            help: `Inntil 100 millioner kroner for skader på andres kjøretøy, eiendom eller eiendeler.`,
        },
        {
            title: 'Rettshjelp hvis du blir del av en tvist',
            checkedRows: 1,
            help: `Du kan få dekket utgifter til rettshjelp på inntil ${spaceToNBSP(
                '100 000',
            )} kroner, hvis du blir part i en tvist.`,
        },
        {
            title: 'Utbetaling hvis du blir medisinsk invalid',
            checkedRows: 1,
            help: `Gir erstatning ved personskade på fører og/eller passasjer ved medisinsk invaliditet på inntil ${spaceToNBSP(
                '200 000',
            )} kroner.`,
        },
        {
            title: 'Utbetaling til etterlatte hvis du dør',
            checkedRows: 1,
            help: `Gir erstatning ved personskade på fører og/eller passasjer ved død på ${spaceToNBSP(
                '100 000',
            )} kroner.`,
        },
    ],
};

export default elsparkesykkelConfig;
