import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';

const helseConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Konsultasjon',
            checkedRows: 1,
            help: 'Konsultasjon, undersøkelse eller behandling innen 10 virkedager.',
        },
        {
            title: 'Fysikalsk behandling',
            checkedRows: 1,
            help: 'Dekker inntil 6 eller 12 behandlinger hos autorisert fysioterapaut, manuellterapaut, kiropraktor, osteopat eller naprapat per 12 måneder.',
        },
        {
            title: `${hyphenAsSoftHyphen('Psykolog-behandling')}`,
            checkedRows: 1,
            help: 'Dekker inntil 10 behandlinger hos autorisert psykolog per 12 måneder. ',
        },
        {
            title: `Psykologisk ${hyphenAsSoftHyphen('første-hjelp')}`,
            checkedRows: 1,
            help: 'Dekker inntil 10 behandlinger hos autorisert psykolog i etterkant av plutselige traumatiske opplevelser, inkl. alle husstandmedlemmer.',
        },
        {
            title: 'Rehabilitering hos fysikalsk behandler',
            checkedRows: 1,
            help: 'Dekker utgifter til rehabilitering hos fysikalsk behandler i inntil 4 uker.',
        },
        {
            title: `${hyphenAsSoftHyphen('Rehabiliterings-opphold')}`,
            checkedRows: 1,
            help: 'Dekkes i henhold til epikrise og etter behandling betalt av forsikringen — i inntil 14 dager.',
        },
        {
            title: `Reise- og ${hyphenAsSoftHyphen('oppholds-utgifter')}`,
            checkedRows: 1,
            help: 'Dekker utgifter til reise og opphold i forbindelse med behandling.',
        },
        {
            title: `Medisiner under ${hyphenAsSoftHyphen('sykehus-opphold')}`,
            checkedRows: 1,
            help: 'Dekker medisiner og preparater brukt i behandling på behandlingssted.',
        },
        {
            title: `Medisinsk ${hyphenAsSoftHyphen('råd-givning')}`,
            checkedRows: 1,
            help: 'Medisinsk rådgivning fra Falck Helseformidling i forbindelse med behandlingsbehov.',
        },
        {
            title: 'Second opinion',
            checkedRows: 1,
            help: 'Ny medisinsk vurdering fra legespesialist ved livstruende sykdom og skade, eller før spesielt risikofylt behandling.',
        },
        {
            title: 'Videolege Kry',
            checkedRows: 1,
            help: 'Gir deg og husstanden din tilgang til videolegetjenesten Kry.',
        },
    ],
};

export default helseConfig;
