import React from 'react';

import { IconTypes } from '@eika/icon';
import { Link } from '@eika/link';

import { ForsikringProduktkode } from '../comparison-table-config/product-config';
import { useConfig } from '../default-config';
import { useTranslation } from '../translations';
import { withComparisonTablePrefix } from '../utils/withDataTrackPrefix';

import './vilkar-and-produktinformasjon.scss';

export const VilkarAndProduktinformasjonLinks = () => {
    const { product } = useConfig();
    const { comparisonTableLinkTexts } = useTranslation();

    if (!product || !ForsikringProduktkode[product]) return null;

    const produktkode = ForsikringProduktkode[product];
    const vilkarUrl = `/forsikring-min-side-web/rest/resource/open/vilkar/${produktkode}`;
    const produktinformasjonUrl = `${vilkarUrl}P`;

    return (
        <div className="comparison-table-button-and-links-container__links">
            <Link
                href={vilkarUrl}
                icon={IconTypes.NEDLASTING_24}
                iconOnRightSide
                data-track-id={withComparisonTablePrefix('vilkar', product)}
                data-track-linkname={`vilkar-${produktkode}-${product}`}
            >
                {comparisonTableLinkTexts.vilkarPdf}
            </Link>
            <Link
                href={produktinformasjonUrl}
                icon={IconTypes.NEDLASTING_24}
                iconOnRightSide
                data-track-id={withComparisonTablePrefix('produktinformasjon', product)}
                data-track-linkname={`produktinformasjon-${produktkode}-${product}`}
            >
                {comparisonTableLinkTexts.produktinformasjonPdf}
            </Link>
        </div>
    );
};
