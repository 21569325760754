import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const tilhengerConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Kasko',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Brann/tyveri',
            description: 'Dekkjer enkelte skader',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekkjer utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan for eksempel vere tvisteløysing ved kjøp og sal av tilhengar.`,
            checkedRows: 2,
        },
        {
            title: 'Brann og tjuveri',
            help: 'Vi dekkjer skadar ved brann, kortslutning i leidningsnett, lynnedslag og eksplosjon. Vi dekkjer også tjuveri og forsøk på tjuveri av eller frå tilhengar, og skadeverk som følgje av forsøk på tjuveri.',
            checkedRows: 2,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('til-hengar')}`,
            help: 'Dekkjer skadar ved samanstøyt, utforkøyring, velt, hærverk eller annan uforutsett hending eller eitt uhell som skjer utan forvarsel på eigen tilhengar.',
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Sikkerheits-utstyr')}`,
            help: 'Dekkjer sikkerheitsutstyr som tilhøyrer tilhengaren.',
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Fast-montert tilleggs-utstyr')}`,
            help: `Dekkjer fastmontert tilleggsutstyr, som dekor og spesiallakk for inntil ${spaceToNBSP(
                '20 000',
            )} kroner. Erstatninga er avgrensa til ${spaceToNBSP('50 %')} av tilhengaren sin marknadsverdi.`,
            checkedRows: 1,
        },
    ],
};

export default tilhengerConfig;
