import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const bilConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Bil Pluss',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Kasko',
            description: 'Nyere bil med høy verdi',
            color: 'medium',
        },
        {
            title: `${hyphenAsSoftHyphen('Del-kasko')}`,
            description: 'Eldre bil med lavere verdi',
            color: 'light',
        },
        {
            title: 'Ansvar',
            description: 'Kun det lovpålagte',
        },
    ],
    rows: [
        {
            title: `Ansvar og ${hyphenAsSoftHyphen('retts-hjelp')}`,
            help: `Ubegrenset beløp for skader på personer, og inntil 100 millioner kroner for skader på andres kjøretøy, eiendom eller eiendeler. Skader på egen bil er ikke dekket av ansvarsforsikringen. For å få dette dekket må du enten ha delkasko, som dekker noen skadetilfeller, eller kaskoforsikring. Du kan få dekket utgifter til rettshjelp på inntil ${spaceToNBSP(
                '100 000',
            )} kroner, hvis du blir part i en tvist. For eksempel ved kjøp og salg av bil.`,
            checkedRows: 4,
        },
        {
            title: `Fører- og ${hyphenAsSoftHyphen('passasjer-ulykke')}`,
            help: 'Gir erstatning ved personskade på fører og/ eller passasjer.',
            checkedRows: 4,
        },
        {
            title: 'Brann, glass og tyveri',
            help: `Vi dekker skader som følge av brann, kortslutning i ledningsnett, lynnedslag og eksplosjon. I tillegg dekker vi bruddskader på alle bilens vinduer, også glasstak og takluker av glass. Du betaler ingen egenandel hvis glassruten kan repareres. Hvis ruten må byttes betaler du en egenandel på ${spaceToNBSP('3 000')} kroner. Vi dekker tyveri og forsøk på tyveri av eller fra bil, og skadeverk som følge av forsøk på tyveri.`,
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Vei-hjelp')}`,
            help: 'Vi hjelper deg ved motorstopp og annen kjøreteknisk driftsstopp som hindrer deg fra å kjøre videre. Dette inkluderer reparasjon på stedet eller tauing av bil til nærmeste verksted. Du får også veihjelp hvis du går tom for drivstoff eller strøm. Gyldig i hele Europa.',
            checkedRows: 3,
        },
        {
            title: 'Skade på bil',
            help: 'Dekker skader ved sammenstøt, utforkjøring, velt, hærverk eller annen uforutsett hendelse eller uhell som skjer uten forvarsel på eget kjøretøy.',
            checkedRows: 2,
        },
        {
            title: 'Bagasje og eiendeler',
            help: `Dekker skade på personlige eiendeler og bagasje som er i bilen under kjøreturen. Det kan omfatte forskjellige typer gjenstander som blant annet klær, toalettsaker, elektronikk, matvarer, sportsutstyr og verktøy. Med Bil Pluss får du dekket inntil ${spaceToNBSP(
                '30 000',
            )} kroner, og med Kasko får du dekket inntil ${spaceToNBSP('20 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Leiebil',
            help: 'Dekker kostnader ved leiebil når bilen din er til reparasjon eller ikke er i kjørbar stand etter en skade. Med Bil Pluss får du leiebil i inntil 45 dager. For Kasko kan dette kjøpes som et tillegg i inntil 10 dager.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Motor-skade')}`,
            help: `Dekker tilfeldig og plutselig mekanisk skade på motor, girkasse/clutch, drivverk, motorens og automatgirkassens elektroniske hovedstyreenhet, samt hybrid-/elbilers batteripakke. For Bil Pluss kan bilen ikke ha kjørt mer enn ${spaceToNBSP(
                '200 000',
            )} km eller være eldre enn 10 år. For Kasko kan bilen ikke ha kjørt mer enn ${spaceToNBSP(
                '60 000',
            )} km eller være eldre enn 6 år.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Ekstra-utstyr')}`,
            help: `Dekker skader på tilleggsutstyr eller tilbehør som er installert i eller på bilen, i tillegg til de standardkomponentene som følger med bilen. Ekstrautstyr kan være alt fra lykter, felger, stereoanlegg, navigasjonssystemer, takstativer, spoiler, ekstra lys og lignende. For Bil Pluss gjelder dekning for inntil ${spaceToNBSP(
                '50 000',
            )} kr og for kasko for inntil ${spaceToNBSP('20 000')} kr.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Nybil-garanti')}`,
            help: `Gir deg rett til ny bil av samme merke, modell og årgang dersom reparasjonskostnadene overstiger ${spaceToNBSP(
                '80 %',
            )} av samlet kjøpesum. Med Bil Pluss kan bilen ikke ha kjørt mer enn ${spaceToNBSP(
                '100 000',
            )} km eller være eldre enn 3 år. For Kasko kan bilen ikke ha kjørt mer enn ${spaceToNBSP(
                '15 000',
            )} km eller være eldre enn ett år.`,
            checkedRows: 2,
        },
        {
            title: 'Tap av nøkkel',
            help: `Erstatter mistet eller ødelagt bilnøkkel med inntil ${spaceToNBSP(
                '15 000',
            )} kroner per skadetilfelle, og maksimalt to ganger per forsikringsår. Ingen egenandel.`,
            checkedRows: 1,
        },
        {
            title: 'Feilfylling av drivstoff',
            help: `Dekker tømming, rens av tank og reparasjon på inntil ${spaceToNBSP(
                '50 000',
            )} kroner ved feilfylling. Egenandel på ${spaceToNBSP(
                '8 000',
            )} kroner. Større kostnader behandles som kaskoskade og vil medføre egenandel og bonustap.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Parkerings-skade')} uten bonustap`,
            help: `Hvis bilen din blir skadet når den står parkert, dekker vi reparasjon på inntil ${spaceToNBSP(
                '40 000',
            )} kroner uten bonustap. Gjelder til bilen er 10 år gammel.`,
            checkedRows: 1,
        },
    ],
};

export default bilConfig;
