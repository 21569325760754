import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const husConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Hus Pluss',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Hus',
            description: 'En god husforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: `Brann-, vann- og ${hyphenAsSoftHyphen('tyveri-skader')}`,
            help: 'Vi dekker brannskade på bygning og hageanlegg. Vi dekker også vannskader fra rørledning etter brudd, lekkasje eller oversvømmelse. Det gjelder også vanninntrenging fra terreng, grunn eller avløpsrør. Skader på bygning i forbindelse med innbrudd og tyveri.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Natur-skade')}`,
            help: 'Naturskade som skyldes skred, storm, flom, stormflo, flodbølge, meteorittnedslag, jordskjelv eller vulkanutbrudd.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: 'Som huseier kan du få dekket utgifter til rettshjelp, hvis du blir part i en rettslig tvist eller juridisk konflikt.',
            checkedRows: 2,
        },
        {
            title: `Bygning inntil 10 kvm er ${hyphenAsSoftHyphen('med-forsikret')}`,
            help: 'Frittstående bygninger på inntil 10 kvadratmeter på forsikringsstedet er medforsikret når bygget er nevnt i forsikringsbeviset.',
            checkedRows: 2,
        },
        {
            title: `Tap av ${hyphenAsSoftHyphen('husleie-inntekter')}`,
            help: 'Vi dekker tapte husleieinntekter som følge av skade som dekkes av forsikringen.',
            checkedRows: 2,
        },
        {
            title: `Utett ${hyphenAsSoftHyphen('våt-rom')}`,
            help: `Plutselig og uforutsett skade på våtrom. Ved skade på gulv eller vegg i våtrom hvor rør eller vanntett sjikt/membran er eldre enn 20 år, forhøyes egenandelen med kr ${spaceToNBSP('10 000')}.`,
            checkedRows: 2,
        },
        {
            title: `Utett ${hyphenAsSoftHyphen('ytter-tak')}`,
            help: `Plutselig og uforutsett følgeskade ved vanninntrenging gjennom utett yttertak som er 40 år eller yngre. Når bygningens taktekking er eldre enn 30 år, eller taktekking er eldre enn 15 år på flatt tak, forhøyes egenandelen med kr ${spaceToNBSP('10 000')}.`,
            checkedRows: 1,
        },
        {
            title: 'Skade på våtrom',
            help: `Plutselig og uforutsett skade på våtrom. Egenandelen forhøyes med ${spaceToNBSP('6 000')} kroner per skade ved skade på gulv eller vegg i våtrom hvor rør eller vanntett sjikt/ membran er eldre enn 40 år.`,
            checkedRows: 1,
        },
        {
            title: `Følgeskade ved ${hyphenAsSoftHyphen('håndverker-feil')}`,
            help: 'Følgeskader på bygning som følge av materialfeil, konstruksjonsfeil, prosjekteringsfeil eller uriktig montasje. Dette gjelder virksomhet med eget organisasjonsnummer. Forsikringen gjelder i inntil 10 år etter at arbeidet er ferdigstilt.',
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Sol-celle')}`,
            help: `Erstatter tapt strømproduksjon til eget bruk med inntil 50 kroner dagen hvis solcelleanlegget montert på bygget blir skadet. Dekker inntil 24 måneder tapt produksjon og maks ${spaceToNBSP('30 000')} kroner per skade.`,
            checkedRows: 1,
        },
    ],
};

export default husConfig;
