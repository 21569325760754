import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { QueryClient, QueryClientProvider } from 'react-query';

import { loadContainers } from '@eika-infrastruktur/render';

import ComparisonTableApp from './containers/comparison-table-app';
import HeaderWidgetApp from './containers/header-widget-app';
import { UforekalkulatorApp } from './containers/uforekalkulator-app';
import { defaultConfig } from './default-config';
import getCssVariableValue from './utils/getCssVariableValue';

import './styles/main.scss';

const globalCssNamespace = 'forsikring-widgets';

const comparisonTableNamespace = 'forsikring.widgets-comparison-table';
const uforekalkulator = 'forsikring.widgets-uforekalkulator';
const headerWidgetNamespace = 'forsikring.widgets-header';

const staleTime = 60 * 60 * 5; // 5 min
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime,
        },
    },
});

const renderApp = (
    MyApp: React.ComponentType<React.PropsWithChildren<unknown>>,
    namespace: string,
    appDefaultConfig: Record<string, unknown> = defaultConfig,
): void => {
    loadContainers(
        namespace,
        () => (
            <React.StrictMode>
                <QueryClientProvider client={queryClient}>
                    <SkeletonTheme
                        baseColor={getCssVariableValue('--white')}
                        highlightColor={getCssVariableValue('--grey-light')}
                    >
                        <div className={globalCssNamespace}>
                            <MyApp />
                        </div>
                    </SkeletonTheme>
                </QueryClientProvider>
            </React.StrictMode>
        ),
        appDefaultConfig,
    );
};

renderApp(ComparisonTableApp, comparisonTableNamespace);
renderApp(UforekalkulatorApp, uforekalkulator);
renderApp(HeaderWidgetApp, headerWidgetNamespace);
