import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const innboConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Innbu Pluss',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Innbu Standard',
            description: 'Ei god innboforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: `Brann-, vass- og ${hyphenAsSoftHyphen('tjuveri-skadar')}`,
            help: 'Blir tinga dine øydelagde av vasskadar, skada eller sotskadd i brann, eller råka av kortslutning/lynnedslag så erstattar vi gjenstandane dine. Blir du utsett for tjuveri i heimen din, erstattar vi gjenstandane som blir øydelagde eller stole.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Tjuveri fra ute-areal')}`,
            help: `Dekkjer tjuveri frå utearealet på forsikringsstaden. Innbu Pluss dekkjer inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Innbo dekkjer tjuveri inntil ${spaceToNBSP('30 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Tjuveri av sykkel',
            help: `Dekkjer tjuveri av sykkel utanfor forsikringsstaden. Er sykkelen registrert i eit FG-godkjent sykkelregister, blir eigenandelen halvert ved tjuveri. Innbo Pluss dekkjer sykkel inntil ${spaceToNBSP(
                '40 000',
            )} kroner. Med Innbu blir sykkel dekka inntil ${spaceToNBSP('10 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Om-bygging av bustad på grunn av ulukke eller handi-kap')}`,
            help: 'Viss ein medlem av husstanden blir rullestolbrukar etter ei ulukke – eller får eit barn som er avhengig av rullestol på grunn av eit medfødd handikap – dekkjer vi nødvendige meirutgifter til ombygging av bustad.',
            checkedRows: 2,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('mat-varer og lukt-skade, på frysar og kjøle-skap')}`,
            help: 'Dekkjer skadar på matvarer som følgje av tilfeldig temperaturstiging i frysar, kjøleskap og kjølehjørne. Dette gjeld også lukt    ­ skade som følgje av skadar på matvarer.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Identitets-tjuveri')}`,
            help: 'Dekkjer identitetstjuveri der nokon utan ditt samtykke, bruker din identitet til å utføre økonomisk svindel eller annan kriminell handling.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Skade-dyr')}`,
            help: `Om du får problem med mus, rotter, skjeggkre, veggdyr eller andre skadeinsekt dekkjer vi bekjemping med inntil ${spaceToNBSP('150 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Transport-skade ved flytting')}`,
            help: `Dekkjer skadar på innbu i samband med flytting til ny bustad, viss du flyttar sjølv. Gjeld for inntil ${spaceToNBSP(
                '100 000',
            )} kroner.`,
            checkedRows: 1,
        },
        {
            title: 'Uhell heime',
            help: `Vi gir inntil ${spaceToNBSP(
                '100 000',
            )} i erstatning om tinga dine blir skadde eller øydelagde i eit uhell. Til dømes om TV-en dett i golvet. Eigendelen er 2000 kroner uansett kor høg eigendel du har valt elles.`,
            checkedRows: 1,
        },
        {
            title: 'Uhell i heile verda',
            help: `Mista du mobilen i Trevi-fontena eller køyrde bussen over hovudtelefonane dine? Vi dekkjer plutselege og uføresette skadar på eigendelane dine med inntil ${spaceToNBSP(
                '30 000',
            )} kroner uansett kvar du er i verda. Anten det er på butikken for å handle, eller om du er på sydenferie. Eigendelen er 2000 kroner uansett kor høg eigendel du har valt elles.`,
            checkedRows: 1,
        },
    ],
};

export default innboConfig;
