export enum ForsikringProduktkoder {
    // Personforsikring
    BARN_OG_UNGDOM = 'S12',
    KRITISK_SYKDOM = 'S80',
    // Skadeforsikring
    BEDRIFT = 'N05',
    HANDVERKER = 'N06',
    NAERINGSFORSIKRING = 'N07',
    BUTIKK = 'N10',
    KONTOR = 'N15',
    BOLIGTILUTLEIE = 'N22',
    HUSEIER = 'N25',
    ANSVAR = 'N50',
    CYBER = 'N53',
    MASKIN = 'N60',
    BANKDRIFT = 'N70',
    VIRKE_YRKESSKADE = 'N75',
    FORMUESKADE = 'N95',
    LANDBRUKBOLIGHUS = 'L05',
    LANDBRUKDRIFTSBYGNING = 'L10',
    LANDBRUKLOSOREAVLBESETN = 'L15',
    DRIFTSBLOSORENEDLAGTBRUK = 'L17',
    BOLIG = 'P05',
    INNBO = 'P07',
    INNBOBOS = 'P08',
    FRITIDSBOLIG = 'P20',
    HELSEFORSIKRING_N16 = 'N16',
    PERSONALFORSIKRING = 'N17',
    VIRKE_PERSONALFORSIKRING_NAVNEBASERT = 'N76',
    VIRKE_PERSONAL_SELVADMINISTRERT = 'N77',
    YRKESSKADE = 'N20',
    KOLLEKTIVULYKKE = 'N80',
    REISEBEDRIFT = 'N85',
    REISEKORTTID = 'N90',
    REISE = 'P10',
    UTVIDETREISETID = 'P11',
    ULYKKE = 'P15',
    HELSEFORSIKRING_P16 = 'P16',
    STUDENTIUTLANDET = 'P40',
    EIKA_PLUSS = 'P44',
    UNGDOM = 'P45',
    UNGFAMILIE = 'P46',
    EIKAKORT = 'P50',
    INNTEKT = 'P60',
    DRONE = 'P71',
    BONDENSULYKKE = 'L55',
    DROSJE = 'N31',
    NARINGSBIL = 'N33',
    STORBIL = 'N35',
    TILHENGERTRALLE = 'N40',
    LANDBRUKSOGARBEIDSMASKIN = 'L21',
    LANDBRUKSMASKIN = 'L20',
    LANDBRUK_YRKESKADE = 'L30',
    LANDBRUK_GAARDSBIL = 'L35',
    BIL = 'M05',
    TILHENGER = 'M10',
    MOPED = 'M15',
    VETERANKJORETOY = 'M20',
    MOTORSYKKEL = 'M25',
    MOTORSYKKEL_NY = 'M26',
    SNOSCOOTER = 'M30',
    BOBIL = 'M35',
    ATV = 'M37',
    RUSSEBIL = 'M40',
    TRAKTOR = 'M45',
    HELSEFORSIKRING_BEDRIFT = 'N18',
    HUSEIER_BEDRIFT = 'N30',
    ARBEIDSMASKIN = 'N45',
    TRANSPORTEGENBIL = 'N55',
    TRANSPORTBEDRIFT = 'N56',
    TRANSPORTENGANGS = 'N57',
    VERDIGJENSTAND = 'P25',
    HUND = 'P29',
    HUNDKATT = 'P30',
    FRITIDSBAT = 'P35',
    ONLINEFORSIKRING = 'P53',
    HEST = 'L25',
    HUSDYR = 'L40',
    FJORFE = 'L45',
    HUSDYRINDIVIDUELL = 'L50',
    EIENDELER = 'N04',
    SMA_ELKJORETOY = 'M16',
    SMA_ELKJORETOY_UTLEIE = 'N49',
}
