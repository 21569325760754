import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { spaceToNBSP } from '../../utils/spaceToNBSP';

const hundConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Hund Pluss',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Hund Standard',
            description: 'En god hundeforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: 'Veterinærutgifter',
            help: `Dekker utgifter til medisiner og preparater som veterinæren foreskriver.`,
            checkedRows: 2,
        },
        {
            title: 'Atopi/allergi',
            help: `Dekker utgifter knyttet til allergi og atopi inntil ${spaceToNBSP(
                '5 000',
            )} kroner, utvides til ${spaceToNBSP('15 000')} kroner for Hund Pluss. `,
            checkedRows: 2,
        },
        {
            title: 'MR/CT',
            help: `Dekker utgiftene for nødvendig MR og CT inntil ${spaceToNBSP(
                '5 000',
            )} kroner per år, utvides til ${spaceToNBSP('10 000')} kroner med Hund Pluss.`,
            checkedRows: 2,
        },
        {
            title: 'Bruksverdi',
            help: 'Valgfritt tillegg som lar deg forsikre bruksverdien til hunden din om den er trent for et spesifikt bruksområde hvis den mister bruksverdien.',
            checkedRows: 2,
        },
        {
            title: 'Liv',
            help: 'Valgfritt tillegg som dekker kostnaden for å erstatte hunden om den skulle dø eller komme bort.',
            checkedRows: 2,
        },
        {
            title: 'Valpekullforsikring',
            help: 'Valgfritt tillegg som dekker livsforsikring og veterinærdekning for valpekull. Gyldig fra valpene er 5 uker gamle til de blir 15 uker eller dagen etter levering til ny eier. Dekker også skjulte feil og mangler.',
            checkedRows: 2,
        },
        {
            title: 'Vanntrening/rehabilitering',
            help: `Dekker utgifter for rehabilitering av hunden etter sykdom eller skade med inntil ${spaceToNBSP(
                '10 000',
            )} kroner per år.`,
            checkedRows: 1,
        },
        {
            title: 'Valpeforsikring',
            help: 'Dekker valpen sine veterinærkostnader inntil summen du velger frem til valpen er 12 dager gammel.',
            checkedRows: 1,
        },
        {
            title: 'Tannsykdommer',
            help: 'Dekker tannsykdommer, tannkjøttsykdommer, trekking av tilbakeholdte melketenner og tannstillingsfeil som medfører medisinske problemer.',
            checkedRows: 1,
        },
    ],
};

export default hundConfig;
