import React from 'react';

import { StandardButton } from '@eika/button';
import { Link, LinkType } from '@eika/link';

import { ForsikringProduktkode } from '../comparison-table-config/product-config';
import { useConfig } from '../default-config';
import { withComparisonTablePrefix } from '../utils/withDataTrackPrefix';

export interface Props {
    buttonText: string;
}

export const BuyForsikringButton = ({ buttonText }: Props) => {
    const { kjopbuttonlink, kjopbuttonjsevent, product } = useConfig();

    const produktkodeAndProdukt =
        product && ForsikringProduktkode[product] ? `${ForsikringProduktkode[product]}-${product}` : 'ukjent-produkt';

    if (kjopbuttonlink?.url)
        return (
            <Link
                href={kjopbuttonlink.url}
                linkType={LinkType.BUTTON_STANDARD_POSITIVE}
                data-track-id={withComparisonTablePrefix('kjop-forsikring', product)}
                data-track-linkname={produktkodeAndProdukt}
            >
                {buttonText}
            </Link>
        );

    if (kjopbuttonjsevent)
        return (
            <StandardButton
                data-track-id={withComparisonTablePrefix('spor-oss-om-pris')}
                data-track-linkname={produktkodeAndProdukt}
                onClick={() => {
                    window.dispatchEvent(new Event(kjopbuttonjsevent));
                }}
            >
                {buttonText}
            </StandardButton>
        );

    return null;
};
