import React from 'react';

import './header-widget.scss';

const HeaderWidget = () => (
    <div className="forsikring-header-widget">
        <span className="forsikring-header-widget__text">Vi er eid av SpareBank 1 Gruppen, DNB og Eika Gruppen</span>
    </div>
);

export default HeaderWidget;
