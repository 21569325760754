import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const snocooterConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Kasko',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Brann/tyveri',
            description: 'Dekker enkelte skader',
            color: 'medium',
        },
        {
            title: 'Ansvar',
            description: 'Dekker kun det lovpålagte',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: `Dekker ubegrenset beløp for skader på personer, og inntil 100 millioner kroner for skader på andres kjøretøy, eiendom eller eiendeler. Skader på egen snøscooter er ikke dekket av ansvarsforsikringen. For å få dette dekket må du enten ha brann/tyveri, som dekker noen skadetilfeller, eller kaskoforsikring.`,
            checkedRows: 3,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: `Dekker utgifter til juridisk bistand på inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Dette kan for eksempel være tvisteløsning ved kjøp og salg av snøscooter.`,
            checkedRows: 3,
        },
        {
            title: `Fører- og ${hyphenAsSoftHyphen('passasjer-ulykke')}`,
            help: `Gir erstatning ved personskade på fører og/eller passasjer.`,
            checkedRows: 3,
        },
        {
            title: 'Brann',
            help: 'Vi dekker skader ved brann, kortslutning i ledningsnett, lynnedslag og eksplosjon.',
            checkedRows: 2,
        },
        {
            title: 'Tyveri',
            help: 'Vi dekker skader ved tyveri og forsøk på tyveri av eller fra snøscooter, og skadeverk som følge av forsøk på tyveri.',
            checkedRows: 2,
        },
        {
            title: 'Bagasje',
            help: `Dekker bagasje med inntil ${spaceToNBSP(
                '10 000',
            )} kroner. Gjelder ved skade på bagasje eller tyveri.`,
            checkedRows: 2,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('snø-scooter')}`,
            help: `Dekker skader ved sammenstøt, utforkjøring, velting, hærverk eller annen uforutsett hendelse eller et uhell som skjer uten forvarsel på egen snøscooter.`,
            checkedRows: 1,
        },
    ],
};

export default snocooterConfig;
