import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

const kritisksykdomConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Kreft',
            checkedRows: 1,
            help: 'Ondarta svulstar, Blodkreft, Lymfekreft, Beinmargskreft',
        },
        {
            title: 'Hjarte',
            checkedRows: 1,
            help: 'Hjarteinfarkt, Hjarteoperasjon (bypassoperasjon, PCI), Hjarteklaffkirurgi, Utposning på hovudpulsåra (aortaaneurisme)',
        },
        {
            title: 'Hjerne',
            checkedRows: 1,
            help: `Hjerneslag, aneurisme.`,
        },
        {
            title: 'Autoimmune og nevrologiske sjukdommar',
            checkedRows: 1,
            help: `ALS og andre motornevronsjukdommar, Multippel sklerose (MS), Parkinsons sjukdom, Alzheimers sjukdom.`,
        },
        {
            title: 'Anna',
            checkedRows: 1,
            help: `Nyresvikt; organtransplantasjon; blindskap; døvhet; tap av taleevne; alvorlig brannskade; godartede svulster i hjerne, hjernehinne eller ryggmarg; tverrsnittlammelser; tap av ben eller arm.`,
        },
    ],
};

export default kritisksykdomConfig;
