import React, { FC } from 'react';

import { Uforekalkulator } from '@eika-forsikring/uforekalkulator';

import { BuyForsikringButton } from '../components/buy-forsikring-button';
import { useTranslation } from '../translations';
import { NAV_UFORE_URL } from '../utils/constants';

import '../styles/uforekalkulator-app.scss';

export const UforekalkulatorApp: FC = () => {
    const { uforekalkulatorButtonText } = useTranslation();
    return (
        <Uforekalkulator navUforeLink={NAV_UFORE_URL}>
            <div className="contact-button">
                <BuyForsikringButton buttonText={uforekalkulatorButtonText.kontaktMegButton} />
            </div>
        </Uforekalkulator>
    );
};
