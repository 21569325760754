import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

const ulykkeConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Utvidet ulykke',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Ulykke Standard',
            description: 'En god ulykkesforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: 'Ulykke',
            help: `Kan dekke behandlingsutgiftene dine hvis du har vært i en ulykke og gir erstatning ved varig skade eller død.`,
            checkedRows: 2,
        },
        {
            title: 'Medisinsk invaliditet',
            help: 'Erstatning ved varig medisinsk invaliditet.',
            checkedRows: 2,
        },
        {
            title: 'Dødsfallerstatning',
            help: `Erstatning til ektefelle, samboer eller arvinger hvis du dør i en ulykke.`,
            checkedRows: 2,
        },
        {
            title: 'Utvidet dekning ved utvalgte fritidsaktiviteter',
            help: 'Forsikringen kan utvides til å dekke fjellklatring, kiting og andre sports- og fritidsaktiviteter.',
            checkedRows: 1,
        },
    ],
};

export default ulykkeConfig;
