import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { spaceToNBSP } from '../../utils/spaceToNBSP';

const elsparkesykkelConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Skader på deg sjølv eller andre',
            checkedRows: 1,
            help: 'Uavgrensa beløp for skadar på personar.',
        },
        {
            title: 'Skader på andres eigedom eller eigendelar',
            checkedRows: 1,
            help: 'Inntil 100 millionar kroner for skadar på andres køyretøy, eigedom eller eigendelar.',
        },
        {
            title: 'Rettshjelp',
            checkedRows: 1,
            help: `Du kan få dekt utgifter til rettshjelp på inntil ${spaceToNBSP(
                '100 000',
            )} kroner, viss du blir part i ein tvist.`,
        },
        {
            title: 'Utbetaling ved medisinsk invaliditet',
            checkedRows: 1,
            help: `Gir erstatning ved personskade på førar og/eller passasjer ved medisinsk invaliditet på inntil ${spaceToNBSP(
                '200 000',
            )} kroner.`,
        },
        {
            title: 'Utbetaling ved død',
            checkedRows: 1,
            help: `Gir erstatning ved personskade på førar og/eller passasjer ved død på ${spaceToNBSP(
                '100 000',
            )} kroner.`,
        },
    ],
};

export default elsparkesykkelConfig;
