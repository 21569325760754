import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const ungConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'UNG verdigjenstand',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'UNG Standard',
            description: 'En god forsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: 'Reise Pluss',
            help: `Vår reise- og ${hyphenAsSoftHyphen(
                'avbestillings-forsikring',
            )} gjelder med en gang du reiser hjemmefra, og gjelder både på jobb og ferie i inntil 77 dager.`,
            checkedRows: 2,
        },
        {
            title: 'Innbo Pluss',
            help: `Innboforsikringen sikrer alle tingene dine. Det er like viktig enten du eier eller leier.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Ulykkes-forsikring')}`,
            help: `Ulykkesforsikring kan dekke behandlingsutgiftene dine hvis du har vært i en ulykke og gir erstatning ved varig skade eller død.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Verdi-gjenstand')}`,
            help: 'For noen kroner ekstra i måneden kan du velge å forsikre dine verdifulle gjenstander. Da blir prisen 200 kroner i måneden.',
            checkedRows: 1,
            subTexts: [{ text: `inntil ${spaceToNBSP('15 000')} kr` }],
        },
    ],
};

export default ungConfig;
