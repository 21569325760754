import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const fritidsboligConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Hytte pluss',
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Hytte',
            description: 'Ei god hytteforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: `Brann-, vass- og ${hyphenAsSoftHyphen('tjuveri-skadar')}`,
            help: 'Vi dekkjer brannskade på bygning og hageanlegg. Vi dekkjer også vasskadar frå røyrleidning etter brot, lekkasje eller oversvømming. Det gjeld også vassinntrenging frå terreng, grunn eller avløpsrøyr. Skadar på bygning i samband med innbrot og tjuveri.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Natur-skade')}`,
            help: 'Dekkjer naturskade som skuldast skred, storm, flaum, stormflo, flodbølge, meteorittnedslag, jordskjelv eller vulkanutbrot.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: 'Du kan få dekka utgifter til rettshjelp, viss du blir part i ein rettsleg tvist eller juridisk konflikt.',
            checkedRows: 2,
        },
        {
            title: `Utett ${hyphenAsSoftHyphen('våt-rom')}`,
            help: 'Vi dekkjer følgeskadar etter utett våtrom.',
            checkedRows: 2,
        },
        {
            title: 'Skader på småbygg',
            help: 'Bygningar på same gards- og bruksnummer som er nemnt i forsikringsbeviset er automatisk medforsikra i hytteforsikringa.',
            checkedRows: 2,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('hage-anlegg')}`,
            help: 'Dekkjer skadar på hageanlegg opp til 5 dekar, som oppstår plutseleg og uforutsett. Dette gjeld også for små bygningar på inntil 10 kvadratmeter. Dette inkluderer gjerde, flaggstang, parabolantenne, utvendig symjebasseng, også badestamp og boblebad, med tilknytte leidningar.',
            checkedRows: 2,
        },
        {
            title: 'Følgeskader ved håndverkerfeil',
            help: 'Følgeskadar på bygning som følgje av materialfeil, konstruksjonsfeil, prosjekteringsfeil eller uriktig montering. Dette gjeld verksemd med eige organisasjonsnummer. Forsikringa gjeld i inntil 10 år etter at arbeidet er ferdigstilt.',
            checkedRows: 1,
        },
        {
            title: 'Gjenoppføring av hytte',
            help: `Ved skade som er større enn  ${spaceToNBSP(
                '75 %',
            )} av kva det kostar å gjenoppføre hytte, kan du velje å få oppgjer som om hytta er totalskadd.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Sol-celle')}`,
            help: `Erstatter tapt straumproduksjon til eige bruk med inntil 50 kroner dagen viss solcelleanlegget montert på bygningen blir skadd. Dekkjer inntil 24 md. tapt produksjon og maks ${spaceToNBSP(
                '30 000',
            )} kroner per skade.`,
            checkedRows: 1,
        },
    ],
};

export default fritidsboligConfig;
