import { TranslationsConfigType } from '.';
import { useConfig } from '../default-config';

export type FormatArgs = (JSX.Element | string)[];
export const format = (value: string, ...args: (string | number)[]): string => {
    let i = 0;
    return value.replace(/{}/g, (): string => {
        // eslint-disable-next-line no-plusplus
        return typeof args[i] !== 'undefined' ? args[i++].toString() : '';
    });
};

const useTranslation = (): TranslationsConfigType => {
    const { siteLang, translations } = useConfig();
    return translations[siteLang];
};

export default useTranslation;
