import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const droneConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: `${hyphenAsSoftHyphen('Bakke-kasko')}`,
            description: 'Mest mogleg tryggleik',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: `${hyphenAsSoftHyphen('Drone-ansvar')}`,
            description: 'Dekkjer kun det lovpålagte',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: 'Ansvar',
            help: 'Dekkjer rettsleg erstatningsansvar viss drona di gjer skade på andre personar og andre sine ting i medhald av EC785/2004.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Retts-hjelp')}`,
            help: 'Dekkjer nødvendige kostnader til advokat og andre kostnader i samband med rettshjelp knytt til drona di.',
            checkedRows: 2,
        },
        {
            title: 'Brann',
            help: 'Dekkjer viss drona blir skadd eller går tapt i brann.',
            checkedRows: 1,
        },
        {
            title: 'Vasskade',
            help: 'Dekkjer viss drona blir skadd eller går tapt på grunn av vasskade.',
            checkedRows: 1,
        },
        {
            title: 'Tjuveri',
            help: 'Dekkjer viss drona blir stole.',
            checkedRows: 1,
        },
        {
            title: 'Uhell',
            help: 'Dekkjer viss drona blir skadd eller går tapt i ulykke mens ho ikkje er i bruk.',
            checkedRows: 1,
        },
        {
            title: 'Tilbehøyr',
            help: `Dekkjer plutseleg og uforutsett tap eller skade på tilbehøyr til drona, slik som kamera. Dekkjer inntil ${spaceToNBSP(
                '10 000',
            )} kroner.`,
            checkedRows: 1,
        },
    ],
};

export default droneConfig;
