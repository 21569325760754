import { BaseConfig, useConfig as hook } from '@eika-infrastruktur/config-provider';

import { ForsikringProducts } from './comparison-table-config/product-config';
import { nbTranslations, nnTranslations, TranslationsConfigType } from './translations';

export interface Config extends BaseConfig {
    product?: ForsikringProducts;
    kjopbuttonjsevent?: string;
    kjopbuttonlink?: { url: string };
    siteLang: 'nb' | 'nn';
    translations: {
        nb: TranslationsConfigType;
        nn: TranslationsConfigType;
    };
}

export const defaultConfig: Config = {
    product: undefined,
    kjopbuttonjsevent: undefined,
    kjopbuttonlink: undefined,
    siteLang: 'nb' as const,
    translations: {
        nb: nbTranslations,
        nn: nnTranslations,
    },
};

function createHook<T>(): () => T {
    return () => hook<T>();
}

export const useConfig = createHook<typeof defaultConfig>();
