import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';
import { spaceToNBSP } from '../../utils/spaceToNBSP';

const innboConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Innbo Pluss',
            description: 'Mest mulig trygghet',
            color: 'dark',
            recommendationLabel: 'Vår beste forsikring',
        },
        {
            title: 'Innbo',
            description: 'En god innboforsikring',
            color: 'medium',
        },
    ],
    rows: [
        {
            title: `Brann-, vann- og ${hyphenAsSoftHyphen('tyveri-skader')}`,
            help: 'Blir tingene dine ødelagt av vannskader, skadet eller nedsotet i brann, eller rammet av kortslutning/lynnedslag så erstatter vi gjenstandene dine. Blir du utsatt for tyveri i hjemmet ditt erstatter vi gjenstandene som blir ødelagt eller stjålet.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Tyveri fra ute-areal')}`,
            help: `Dekker tyveri fra utearealet på forsikringsstedet. Innbo Pluss dekker inntil ${spaceToNBSP(
                '100 000',
            )} kroner. Innbo dekker tyveri inntil ${spaceToNBSP('30 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: 'Tyveri av sykkel',
            help: `Dekker tyveri av sykkel utenfor forsikringsstedet. Er sykkelen registrert i et FG-godkjent sykkelregister, halveres egenandelen ved tyveri. Innbo Pluss dekker sykkel inntil ${spaceToNBSP(
                '40 000',
            )} kroner. Med Innbo dekkes sykkel inntil ${spaceToNBSP('10 000')} kroner.`,
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Om-bygging av bolig på grunn av ulykke eller handi-kap')}`,
            help: 'Hvis et medlem av husstanden blir rullestolbruker etter en ulykke – eller får et barn som er avhengig av rullestol på grunn av et medfødt handikap – dekker vi nødvendige merutgifter til ombygging av bolig.',
            checkedRows: 2,
        },
        {
            title: `Skade på ${hyphenAsSoftHyphen('mat-varer og lukt-skade, på fryser og kjøle-skap')}`,
            help: 'Dekker skader på matvarer som følge av tilfeldig temperaturstigning i fryser, kjøleskap og kjølehjørne. Dette gjelder også luktskade som følge av skader på matvarer.',
            checkedRows: 2,
        },
        {
            title: `${hyphenAsSoftHyphen('Identitets-tyveri')}`,
            help: 'Dekker identitetstyveri der noen uten ditt samtykke, bruker din identitet til å begå økonomisk svindel eller annen kriminell handling.',
            checkedRows: 2,
        },
        {
            title: 'Skade på innbo',
            help: `Plutselige og uforutsette skader på eiendeler som er i huset ditt. Gjenstanden som er ødelagt må kunne vises frem. Dekker eiendeler inntil ${spaceToNBSP(
                '100 000',
            )} kroner.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Transport-skade ved flytting')}`,
            help: `Dekker skader på innbo i forbindelse med flytting til ny bolig, hvis du flytter selv. Gjelder for inntil ${spaceToNBSP(
                '100 000',
            )} kroner.`,
            checkedRows: 1,
        },
        {
            title: `${hyphenAsSoftHyphen('Uhells-forsikring')}`,
            help: `Dekker plutselige og uforutsette skader på eiendeler med inntil ${spaceToNBSP(
                '30 000',
            )} kroner i hele verden. Gjenstanden må kunne vises frem ved skade.`,
            checkedRows: 1,
        },
    ],
};

export default innboConfig;
