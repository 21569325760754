import TranslationsConfigType from './translationType';

const nnTranslations: TranslationsConfigType = {
    tryAgain: 'Prøv igjen',
    showLess: 'Vis mindre',
    showMore: 'Vis meir',
    cancel: 'Avbryt',
    errors: {
        somethingWentWrong: 'Noko gjekk galt!',
        ohNo: 'Ikkje bra!',
        oops: 'Uff då!',
        timeoutMessage: 'Orsak, dette tok noko lengre tid enn vi hadde tenkt. Du må gjerne prøve om att',
        defaultMessage: 'Orsak, det oppstod ein teknisk feil. Ver vennleg og prøv igjen om ei tid.',
    },
    header: 'Hei på deg',
    buttonText: 'Knappen',
    ekstraText: 'Todo',
    ok: 'Ok',
    comparisonTableButtonTexts: {
        digitaltProdukt: 'Sjekk pris og kjøp forsikring',
        ikkeDigitaltProdukt: 'Spør oss om pris',
    },
    comparisonTableLinkTexts: {
        vilkarPdf: 'Vilkår (pdf)',
        produktinformasjonPdf: 'Produktinformasjon (pdf)',
    },
    uforekalkulatorButtonText: {
        kontaktMegButton: 'Kontakt meg om uføreforsikring',
    },
};

export default nnTranslations;
