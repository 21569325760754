import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';

const helseConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Behandlingsgaranti',
            checkedRows: 1,
            help: 'Konsultasjon, undersøking og behandling innan 10 virkedagar i privat helseteneste.',
        },
        {
            title: 'Fysikalsk behandling',
            checkedRows: 1,
            help: 'Dekkjer opptil 6 eller 12 behandlingar hos autorisert fysioterapeut, manuellterapeut, kiropraktor, osteopat eller naprapat per tolv månader.',
        },
        {
            title: `${hyphenAsSoftHyphen('Psykolog-behandling')}`,
            checkedRows: 1,
            help: 'Dekkjer opptil 10 behandlingar hos autorisert psykolog per tolv månader.',
        },
        {
            title: `Psykologisk ${hyphenAsSoftHyphen('første-hjelp')}`,
            checkedRows: 1,
            help: 'Dekkjer opptil 10 behandlingar hos autorisert psykolog etter plutselege traumatiske opplevingar, inkludert alle husstandsmedlemmer.',
        },
        {
            title: 'Rehabilitering hos fysikalsk behandlar',
            checkedRows: 1,
            help: 'Dekkjer utgifter til rehabilitering hos fysikalsk behandlar i opptil 4 veker.',
        },
        {
            title: `${hyphenAsSoftHyphen('Rehabiliterings-opphald')}`,
            checkedRows: 1,
            help: 'Dekkjes i henhold til epikrise og etter behandling betalt av forsikringa, i opptil 14 dagar.',
        },
        {
            title: `Reise- og ${hyphenAsSoftHyphen('opphalds-utgifter')}`,
            checkedRows: 1,
            help: 'Dekkjer utgifter til reise og opphald i samband med behandling.',
        },
        {
            title: 'Medisinar under sjukehusopphald',
            checkedRows: 1,
            help: 'Dekkjer medisinar og preparat brukt i behandling på behandlingsstaden.',
        },
        {
            title: `Medisinsk ${hyphenAsSoftHyphen('råd-givning')}`,
            checkedRows: 1,
            help: 'Medisinsk rådgiving frå Falck Helseformidling i samband med behandlingsbehov.',
        },
        {
            title: 'Second opinion',
            checkedRows: 1,
            help: 'Ny medisinsk vurdering frå legespesialist ved livstruande sjukdom og skade, eller før spesielt risikofylt behandling.',
        },
        {
            title: 'Videolege Kry',
            checkedRows: 1,
            help: 'Gir deg og husstanden din tilgang til videolegetenesta Kry.',
        },
    ],
};

export default helseConfig;
