import { ComparisonTableProps } from '@eika/comparison-table/lib/ComparisonTableTypes';

import { hyphenAsSoftHyphen } from '../../utils/hyphenAsSoftHyphen';

const kattConfig: ComparisonTableProps = {
    tableHeadColumns: [
        {
            title: 'Ansvar',
            description: '',
        },
    ],
    rows: [
        {
            title: 'Liv',
            checkedRows: 1,
            help: 'Gir erstatning viss katten din døyr eller blir borte. Og dekkjer kostnadene viss katten din må avlivast etter skade eller sjukdom.',
        },

        {
            title: `${hyphenAsSoftHyphen('Bruks-verdi')}`,
            checkedRows: 1,
            help: 'Viss katten din er trena til eit bestemt formål eller er avlsdyr kan du få erstatning viss katten mistar bruksverdien sin.',
        },
        {
            title: `${hyphenAsSoftHyphen('Veterinær-utgifter')}`,
            checkedRows: 1,
            help: 'Dekkjer veterinærutgiftene til katten.',
        },
        {
            title: 'FirstVet',
            checkedRows: 1,
            help: 'Du får tilgang til videoveterinæren FirstVet. Ta veterinærtimen frå mobilen din der og når det passar.',
        },
    ],
};

export default kattConfig;
